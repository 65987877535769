import React from "react";
import { useState, useEffect, useContext } from "react";
import { ApiClient } from "../utils/apiclient";
import { clearSession, getIcon, getStoredUserData } from "../utils/utils";
import { IMenuItem, Notification } from "../utils/types";
import NotificationView from "./notification_view";
import MenuButton from "./menu_button";
import { useRouter } from "next/navigation";
import { useNavigate } from "react-router-dom";
import { CareLogoImage } from "./image-assets";
import Image from './image'
// import { DialogContext } from "../layout";


const Sidebar = (props: any) => {
    const navigate = useNavigate()
    const userData = props.user;
    const menuItems = userData?.menuItems
    // const { showDialog, setShowDialog } = useContext(DialogContext);


    const handleMenuButtonClick = (id: number) => {
        const selectedMenuItem = menuItems.find((menuItem: IMenuItem) => menuItem.id == id)
        if (selectedMenuItem?.text == 'Sign out') {
            clearSession();
            navigate('/login')
        }
        else {
            const path = selectedMenuItem?.text.toLocaleLowerCase();
            navigate(`/${path}`)
        }
    }

    return (<div className="hidden md:block md:fixed bottom-0 start-0 bg-slate-100 h-full px-6 w-2/12 border-e-2">
        {props.user ? <>
            <Image src={CareLogoImage} className="w-6/12 m-2" />
            <div className="h-full flex flex-col justify-between py-4 mb-5">
            <nav className="h-full w-full space-y-4 flex flex-col mt-2">
                {menuItems && menuItems.map((menuItem: IMenuItem,index:number) => {
                    let newIcon = {
                        id: menuItem.id,
                        text: menuItem.text,
                        icon: getIcon(menuItem.icon),
                    }
                   return newIcon;
                }).filter((menuItem: IMenuItem,index:number)=>index < menuItems.length - 1).map((menuItem: IMenuItem) => {
                    return (
                        <MenuButton
                            key={menuItem.id}
                            id={menuItem.id}
                            icon={menuItem.icon}
                            text={menuItem.text}
                            isActive={props.active}
                            clicked={menuItem.clicked}
                            onButtonClick={(id: number) => {
                                handleMenuButtonClick(id);
                            }}
                        />
                    );
                })}
            </nav>
            <div className="flex justify-start items-center mb-8">
                <MenuButton
                    key={'signout'}
                    id={7}
                    icon={getIcon('exit_to_app')}
                    text={"Sign out"}
                    clicked={false}
                    onButtonClick={(id: number) => {
                        handleMenuButtonClick(id);
                    }} />
            </div>
            </div>
        </> : null}
    </div>
    )
}

export default Sidebar;