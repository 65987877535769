import React, { useState } from "react";
import {CareLogoImage} from "./image-assets";
import Image from './image'
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = (_props:any) => {
    const navigate = useNavigate();
   
    return (
        <main className="w-full flex items-center justify-center bg-white">

            <div className="hidden w-full mx-auto md:flex flex-col items-center text-center justify-center space-y-6 ">
                <Image src={CareLogoImage} alt="CARE logo" className="mx-auto w-8/12 md:w-4/12" />
                <p className="text-2xl text-textDefault">Care Vehicle Management System</p>
                <p className="text-sm text-mutedText mt-6 pt-12">All rights reserved</p>
            </div>
            <div className="w-full mx-auto flex flex-col items-center text-center justify-center space-y-6 md:bg-primaryLight h-screen">
              <p className="text-3xl">404: Page Not Found</p>
              <p className="text-2xl">Sorry, we could not find the requested page</p>
              <button className="bg-accent w-4/12" onClick={()=>{navigate(-1)}}>Go Back</button>
            </div>
        </main>
    );
}
export default PageNotFound;