import React, { ChangeEvent, useState } from "react";

const Checkbox =(props:any)=>{
 
    const [isChecked,setIsChecked] = useState<boolean>(props.checked)
  
  const handleCheck=(e:React.MouseEvent<HTMLInputElement>)=> {
    // e.preventDefault();
    let text = props.text;
    let state = false;
    let checkbox = e.currentTarget;
    if (checkbox.checked) {
      if (checkbox.id === "-1") {
        props.hasExtra(true);
      }
      state = true;
    } else {
      if (checkbox.id === "-1") {
        props.hasExtra(false);
      }
      text = props.text;
      state = false;
    }
    props.onChange(state, text);
  }
  
    return (
        <div className="md:w-3/12 flex justify-start items-start space-x-2">
          <input
            id={props.id}
            className="h-4 w-4 mt-4"
            type="checkbox"
            onClick={handleCheck}
          />
          <label>{props.text}</label>
        </div>
    
    );
}

export default Checkbox;
