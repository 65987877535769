import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { useState, FormEvent } from "react";
import { IProjectDetail } from "../../utils/types";

const FundcodeForm = (props:any)=>{
    const [open,setOpen] = useState<boolean>(props.state)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    props.onSubmit(formJson)
    handleClose();
  }
   
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>Add New Fundcode</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete this form to create a new fund code
          </DialogContentText>
          <label>Select Project</label>
          <select
            autoFocus
            required
            id="project"
            name="project"
            className="form-control"><option>---</option>
            {props.projects.map((p:IProjectDetail)=>{
                return <option key={p.id} value={p.code}>{p.code}</option>
            })}</select>
           <label>Code</label>
          <input
            autoFocus
            required
            id="code"
            name="code"
            type="text"
            className="form-control"
            placeholder="Enter fund code"
          />
           <label>Expiration Date</label>
          <input
            autoFocus
            required
            id="expiration"
            name="expiration"
            type="date"
            className="form-control"
          />
          
          <label>Activities <small><i>Separate with comma</i></small></label>
          <input
            autoFocus
            required
            id="activities"
            name="activities"
            type="text"
            placeholder="Enter activities"
            className="form-control"
          />
         
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default FundcodeForm