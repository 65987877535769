import { MdNotifications } from "react-icons/md";


const NotificationView = (props:any) => {
    const classname = "material-icons" + (props.count > 0 ? " " : " text-grey");
    const handleNotificationClick = () => {
      props.onNotificationClick();
    };
    return (
      <div className="flex items-start justify-start cursor-pointer" onClick={handleNotificationClick}>
        <MdNotifications className="text-2xl hover:text-accent"/>
        {props.count > 0 ? (
          <span className="bg-accent text-white rounded-full px-2 ms-0 text-xs">
            {props.count > 9 ? "9+" : props.count}
          </span>
        ) : null}
      </div>
    );
  };
  export default NotificationView;