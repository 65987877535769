import { AccordionSummary } from "@material-ui/core"
import { MdClose, MdEdit } from "react-icons/md"
import { CareAvatarImage } from "../../components/image-assets"
import RequestStatusBadge from "../../components/request_status"
import { IUser, IUserData, Trip } from "../../utils/types"
import { getStatusBadge, isAdmin, formatNumber, getStoredUserData, getUserSession, isAuthenticated } from "../../utils/utils"
import TripsTable from "../trips/trips_table"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Image from '../../components/image'
import AccountEditUserForm from "./account_edit_form"
const AccountSettings = (props: any) => {
    const navigate = useNavigate();
    const userData = getStoredUserData();
    const userSession = getUserSession();
    const [user, setUser] = useState<IUser | IUserData | null>(userData)
    const [userForm,setUserForm] = useState<string>('view')

    useEffect(()=>{
        if(!isAuthenticated()){
            navigate('/login',{state:{targetPath:window.location.pathname}})
        }
    },[])
    return (<>
        <div className="w-full">
            {/* <div className="w-full flex items-center justify-between p2-4">
                <p className="text-2xl font-bold">User Details</p>
            </div> */}
            {isAuthenticated() ? <>
                {userForm == 'edit' ? <AccountEditUserForm user={userData} onClose={()=>{setUserForm('view')}}/>:
                <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                    <div className="p-4 w-full md:w-6/12">
                        <Image src={CareAvatarImage} className="h-40 mx-auto" />
                        <div className="w-full flex justify-center items-center space-x-5 mt-5 py-4">
                        
                            <p className="m-4 text-1xl mx-auto "><RequestStatusBadge status={getStatusBadge(userData?.busy!, 'user')} /></p>
                        </div>
                    </div>
                    <div className="ms-5 md:w-6/12">
                       
                        <div className="text-start mb-4">
                            <label className="text-gray-500">Username </label>
                            <p className="text-1xl">{userData?.username ? userData.username : 'N/A'}</p>
                        </div>
                        <div className="text-start mb-4">
                            <label className="text-gray-500">First Name</label>
                            <p className="text-1xl">{userData?.fname}</p>
                        </div>
                        <div className="text-start mb-4">
                            <label className="text-gray-500">Email Address</label>
                            <p className="text-1xl">{`${userData?.email}`}</p>
                        </div>

                        <div className="text-start mb-4">
                            <label className="text-gray-500">Department</label>
                            <p className="text-1xl">{`${userData?.department_detail?.code}`}</p>
                        </div>

                        <div className="text-start mb-4">
                            <label className="text-gray-500">Location</label>
                            <p className="text-1xl">{`${userData?.location}`}</p>
                        </div>

                    </div>
                    <div className="ms-5 md:w-6/12">
                    <div className="text-start mb-4">
                            <label className="text-gray-500">Employee ID</label>
                            <p className="text-1xl">{userData?.emp_id ? userData.emp_id : 'N/A'}</p>
                        </div>
                        <div className="text-start mb-4">
                            <label className="text-gray-500">Last Name</label>
                            <p className="text-1xl">{userData?.lname}</p>
                        </div>

                        <div className="text-start mb-4">
                            <label className="text-gray-500">Phone Number</label>
                            <p className="text-1xl">{`${userData?.phone}`}</p>
                        </div>

                        <div className="text-start mb-4">
                            <label className="text-gray-500">Position</label>
                            <p className="text-1xl">{`${userData?.position_detail?.value}`}</p>
                        </div>


                    </div>
                    <div className="m-4 flex-col justify-between items-end h-full space-y-4">
                        <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/dashboard') }} />
                        <MdEdit className="text-2xl cursor-pointer hover:text-primary" onClick={() => { setUserForm('edit') }} />
                    </div>
                </div>
}
            </> : <span>You need to login</span>}
        </div>
    </>)
}
export default AccountSettings;