
import { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import { getStoredUserData, isAdmin } from "../../utils/utils";
import NotificationView from "../../components/notification_view";
import { useNavigate } from "react-router-dom";
import { ApiClient } from "../../utils/apiclient";
import SearchBox from "../../components/search_box";
import VehiclesTable from "./vehicles_table";
import { Vehicle } from "../../utils/types";
import TopStrip from "../../components/top_strip";

const Vehicles = (_props: any) => {
  const navigate = useNavigate();
  const notifications = []
  const userData = getStoredUserData();

  const [vehicles, setVehicles] = useState([])
  const [filterdVehicles, setFilteredVehicles] = useState(vehicles)

  const showNotifications = () => {
    navigate(`/notifications/${userData?.id}`)
  }
  const handleClickNew = () => {
    navigate('/vehicles/new')
  }

  const getVehicles = async () => {
    const apiClient = new ApiClient();
    const response = await apiClient.getVehicles();
    if (response.status == 200) {
      const data = response.data;
      setVehicles(data.vehicles)
      setFilteredVehicles(data.vehicles)
    }
  }


  const handleFilter = (keyword: string) => {
    const result = vehicles.filter((vehicle: Vehicle) => vehicle?.pnumber.toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.last_location?.toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.brand.toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.model.toLowerCase().includes(keyword.toLowerCase())
      || String(vehicle?.year).toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.donor?.toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.location.toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.location.toLowerCase().includes(keyword.toLowerCase())
      || vehicle?.fundcode?.toLowerCase().includes(keyword.toLowerCase()));
    setFilteredVehicles(result);
  }
  useEffect(() => {
    getVehicles()
  }, [])
  return (<>
    <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
      <TopStrip user={userData} />
    </div>
    <main className="w-full flex bg-white min-h-full">

      <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
        <Sidebar notificationCount={notifications.length} user={userData} active={3} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
      </div>

      <div className="md:w-9/12 mx-auto space-y-4 mt-2">

        <div className="flex space-x-2 w-full my-2 pb-2 items-center justify-between border-b-2">
          <div className="w-full flex items-center justify-start space-x-2">
            {isAdmin(userData) ? <button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12" onClick={handleClickNew}>New Vehicle</button>:null}

          </div>
        </div>
        <div className="w-full text-textDefault mt-2">
          <div className="w-full flex items-center justify-between pt-2">
            <p className="text-2xl font-bold">Vehicles</p>
            <SearchBox onChange={handleFilter} /></div>

          <VehiclesTable vehicles={filterdVehicles} />
        </div>
      </div>
    </main></>
  );
}

export default Vehicles;