import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { Trip } from "../../utils/types";
import { dateFormat, dateFormatWithTime, getQueryString, getStoredUserData, getUserSession, isAdmin, isAuthenticated, timeTraveled } from "../../utils/utils";
import Image from "../../components/image";
import { CareTripImage } from "../../components/image-assets";
import { MdClose } from "react-icons/md";
import { ApiClient } from "../../utils/apiclient";
import { ChangeEvent, useEffect, useState } from "react";
import { getCityNames } from "postcodes-tz";
import Spinner from "../../components/spinner";
import TopStrip from "../../components/top_strip";

const TripView = (props: any) => {
    const userData = getStoredUserData();
    const session = getUserSession()
    const client = new ApiClient(session?.accessToken);
    const navigate = useNavigate()
    const location = useLocation();
    const state = location.state;
    const params = useParams()
    const tripId = Number(params.tripId)
    const selectedTrip: Trip = state && state.trip ? state.trip : props.trip;
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const [trip, setTrip] = useState<Trip>(selectedTrip)
    const [endMileage, setEndMileage] = useState<number | undefined>(trip?.end_mileage)
    const [startTime, setStartTime] = useState<string | number | undefined>(trip?.start_time)
    const [endTime, setEndTime] = useState<string | number | undefined>(trip?.end_time)
    const [startLocation, setStartLocation] = useState<string>(trip?.start_location)
    const [startCity, setStartCity] = useState<string>(trip?.start_city)
    const [destination, setDestination] = useState<string>(trip?.destination)
    const [destinationCity, setDestinationCity] = useState<string>(trip?.destination_city)
    const [remark, setRemark] = useState<string | undefined>(trip?.remark ? trip?.remark : "")
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    const getTripDetails = async (tripId: number) => {
        console.log("🚀 ~ getTripDetails ~ tripId:", tripId)
        const res = await client.getTripDetail(tripId)
        console.log("🚀 ~ getTripDetails ~ res:", res.data)
        if (res.status == 401) {
            alert('Your session expired. Please signin again');
            navigate('/login', { state: { targetPath: `/trips/view/${tripId}` } })
        }
        else updateDetails(res.data)
    }
    const updateDetails = (trip: Trip) => {
        setTrip(trip);
        setEndMileage(trip.end_mileage)
        setStartTime(trip.start_time)
        setEndTime(trip.end_time)
        setStartLocation(trip.start_location)
        setStartCity(trip.start_city)
        setDestination(trip.destination)
        setDestinationCity(trip.destination_city)
        setRemark(trip.remark)
    }
    const handleRemarkChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRemark(e.currentTarget.value.trim())
    }
    const completeTrip = async () => {
        setIsLoading(true)
        const date = new Date();
        let endtime = Date.parse(dateFormat(date.getTime() / 1000) + " " + endTime) / 1000;
        let starttime = Date.parse(dateFormat(date.getTime() / 1000) + " " + startTime) / 1000;
        let data: Partial<Trip> = {
            id: trip.id,
            request_id: trip.request_id,
            requestor: trip.requestor,
            driver: trip.driver,
            vehicle: trip.vehicle,
            vehicle_id: trip.vehicle_id,
            start_location: startLocation,
            destination: destination,
            end_mileage: endMileage,
            start_time: starttime,
            end_time: endtime,
            remark: remark,
            start_city: startCity,
            destination_city: destinationCity,
            closer: userData?.id,
            status: 'Complete',

        };
        const request = await client.completeTrip(data)
        setIsLoading(false);
        navigate(-1)

    }
    const handleMileageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const mileage = Number(e.currentTarget.value)
        if (mileage < trip.start_mileage) {
            e.currentTarget.classList.add('border')
            e.currentTarget.classList.add('border-red-500')
            setEndMileage(undefined)
        }
        else setEndMileage(mileage)
    }
    const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("🚀 ~ handleEndTimeChange ~ e:", e.currentTarget.value)
        setEndTime(e.currentTarget.value)
    }
    const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("🚀 ~ handleStartTimeChange ~ e:", e.currentTarget.value)
        setStartTime(e.currentTarget.value)

    }
    const handleDestinationChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDestination(e.currentTarget.value)
    }
    const handleDestinationCityChange = (e: ChangeEvent<HTMLSelectElement>) => { setDestinationCity(e.currentTarget.value) }
    const handleStartLocationChange = (e: ChangeEvent<HTMLInputElement>) => { setStartLocation(e.currentTarget.value) }
    const handleStartCityChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setStartCity(e.currentTarget.value)
    }
    // getVehicleHisotory()
    useEffect(() => {
        if (isAuthenticated()) {
            if (tripId || trip == null) getTripDetails(tripId);
        }
        else navigate('/login', { state: { targetPath: target } })
    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={4} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>

            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="w-full">
                    <div className="w-full flex items-center justify-between p2-4">
                        <p className="text-2xl font-bold">Trip Details</p>
                    </div>
                    {trip &&
                        <>
                            <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                                <div className="p-4 w-full md:w-6/12">
                                    <Image src={CareTripImage} height={200} />
                                    <div className="w-full flex justify-center items-center space-x-5 mt-5 py-4">
                                        <p className={`text-2xl ${trip.status == 'Incomplete' ? 'text-red-600' : 'text-green-700'}`}>{`${trip.status}`}</p>
                                    </div>
                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Date: </label>
                                        <p className="text-1xl">{dateFormat(trip.date_created)}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Project: </label>
                                        <p className="text-1xl">{trip.project}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Driver: </label>
                                        <p className="text-1xl">{`${trip.nameOfDriver}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Start Location: </label>
                                        <p className="text-1xl">{`${trip.start_location}, ${trip.start_city}`}</p>
                                    </div>

                                    {trip.start_time ? <div className="text-start mb-4">
                                        <label className="text-gray-500">Start Time: </label>
                                        <p className="text-1xl">{dateFormatWithTime(trip.start_time as number, true)}</p>
                                    </div> : null}
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Start Mileage: </label>
                                        <p className="text-1xl">{`${trip.start_mileage}`}</p>
                                    </div>

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Distance (Km): </label>
                                        <p className="text-1xl">{endMileage ? (endMileage - trip.start_mileage) : 'N/A'}</p>
                                    </div>
                                    {/* <div className="text-start mb-4">
                                <label className="text-gray-500">Status:  </label>
                                <p className="text-1xl">{`${trip.status}`}</p>
                            </div> */}


                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Requestor: </label>
                                        <p className="text-1xl">{`${trip.nameOfRequestor}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Fund Code </label>
                                        <p className="text-1xl">{trip.fundcode}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Vehicle: </label>
                                        <p className="text-1xl">{`${trip.vehicle}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">End Location: </label>
                                        <p className="text-1xl">{`${trip.destination}, ${trip.destination_city}`}</p>
                                    </div>

                                    {trip.end_time ? <div className="text-start mb-4">
                                        <label className="text-gray-500">End time: </label>
                                        <p className="text-1xl">{dateFormatWithTime(trip.end_time as number, true)}</p>
                                    </div> : null}
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">End Mileage (Km): </label>
                                        <p className="text-1xl">{`${endMileage}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Time Used (Hours): </label>
                                        <p className="text-1xl">{timeTraveled(startTime, endTime)}</p>
                                    </div>

                                </div>
                                <div className="m-4 flex-col justify-between items-end h-full">
                                    <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate(-1) }} />

                                </div>
                            </div>
                            <div className="w-full flex-column items-start justify-center mt-5 p-4 border mx-auto">
                                {(trip.driver == userData?.id || isAdmin(userData)) && trip.status == 'Incomplete' ? <>

                                    <div className="flex justify-between items-center space-x-5 mt-5 py-1">
                                        <div className="text-start mb-4">
                                            <label className="text-gray-500">Remark: </label>
                                            <input type="text" name="remark" className="form-control" placeholder="write your comment or remarks" onChange={handleRemarkChange} />
                                        </div>
                                        <div className="text-start mb-4">
                                            <label className="text-gray-500">End Mileage: </label>
                                            <input type="number" className="form-control" placeholder="Enter end mileage" name="end_mileage" id="end_mileage" defaultValue={trip.end_mileage} onChange={handleMileageChange} min={trip.start_mileage} />
                                        </div>
                                        {/* <div className="text-start mb-4">
                                <label className="text-gray-500">End Date: </label>
                                <input type="date" className="form-control" placeholder="Enter end date" name="end_date" id="end_date" defaultValue={trip.date_created} />
                            </div> */}
                                        <div className="text-start mb-4"><label className="text-gray-500">Start Time: </label>
                                            <input type="time" className="form-control" placeholder="Enter start time" name="start_time" id="start_time" defaultValue={trip.start_time} onChange={handleStartTimeChange} />
                                        </div>
                                        <div className="text-start mb-4"><label className="text-gray-500">End Time: </label>
                                            <input type="time" className="form-control" placeholder="Enter end time" name="end_time" id="end_time" defaultValue={trip.end_time} onChange={handleEndTimeChange} />
                                        </div>

                                    </div>
                                    <div className="flex justify-between items-center space-x-5 mt-2 py-1">
                                        <div className="text-start mb-4">
                                            <label className="text-gray-500">Start Location: </label>
                                            <input type="text" className="form-control" placeholder="Enter start location" name="start_location" id="start_location" defaultValue={trip.start_location} onChange={handleStartLocationChange} />
                                        </div>
                                        <div className="text-start mb-4">
                                            <label className="text-gray-500">Start city: </label>
                                            <select className="form-control" name="start_city" id="start_city" defaultValue={trip.start_city} onChange={handleStartCityChange}>
                                                {getCityNames('asc').map((city: string) => {
                                                    return <option key={city} value={city}>{city}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="text-start mb-4">
                                            <label className="text-gray-500">Destination: </label>
                                            <input type="text" className="form-control" placeholder="Enter destination" name="destination" id="destination" defaultValue={trip.destination} onChange={handleDestinationChange} />
                                        </div>
                                        <div className="text-start mb-4">
                                            <label className="text-gray-500">Destination city: </label>
                                            <select className="form-control" name="destination_city" id="destination_city" defaultValue={trip.destination_city} onChange={handleDestinationCityChange}>
                                                {getCityNames('asc').map((city: string) => {
                                                    return <option key={city} value={city}>{city}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="w-full flex-column items-start justify-center mt-5 p-4 border mx-auto"> */}
                                    <div className="flex justify-end items-center space-x-5 mt-2 py-1">
                                        {isLoading ? <div className="md:w-3/12 p-2"><Spinner className="spinner-sm mt-4 " /></div> : <button className="bg-green-700" onClick={completeTrip}>Complete</button>}
                                    </div>
                                    {/* </div> */}
                                </> : <div className="text-start mb-4">
                                    <label className="text-gray-500">Remarks </label>
                                    <p className="text-1xl">{trip.remark}</p>
                                </div>}
                            </div></>
                    }
                </div>
            </div>
        </main>
    </>)
}
export default TripView;