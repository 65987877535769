import { formatNumber } from "../utils/utils";

import ReactApexChart from "react-apexcharts";
const ChargingInstructionsChart = (props: any) => {

    const history: any[] = props.history;
    const totalCost: number = props.totalCost;
    const options = {
        chart: {
            title: 'Charging Instructions',
            toolbar: { show: false }
        },

        labels: history.map((h:any)=>`${h.key} - ${h.project}`),
    }
    const series = history.map((h:any)=>h.distance ? h.distance : h.ratio)

    return (
        <div className="mt-5 w-100 flex items-start justify-start">
            <fieldset className="flex p-4 justify-start items-start space-x-2 border w-100">
                <legend className="px-4 text-start">Charging Instructions</legend>
                {history.length > 0 ? (<>
                    <div className="">
                       <ReactApexChart className="w-full" type="pie" height={300} width={400} options={options} series={series} />
                    </div>
                    <div className="flex flex-col justify-start items-start space-y-1 p-5">
                         {history.map((h:any)=>{
                           return <div className="text-accent text-sm text-start flex justify-start items-start space-x-2 w-100" key={h.key}>
                                        <p>{`${h.key}-${h.project}`}</p>
                                        {h.distance ?<p>{`${formatNumber(h.distance)} Km`}</p>:null}
                                        <p>{`(${((100 * h.ratio)).toFixed(1)}%)`}</p>
                                        <p className="font-bold my-0">{`Cost - Tsh. ${formatNumber(((h.ratio) * totalCost).toFixed(1))}`}</p>
                                    </div>})
}
                    </div>
                    </> ) : null}
            </fieldset></div>
    )
}
export default ChargingInstructionsChart