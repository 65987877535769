import { IconType } from "react-icons";
import { CustomTime, IFuelRequest, IMaintenanceRequest, IRequest, IRequestStatusBadge, ISession, IUserData, User } from "./types";
import { MdApproval, MdApps, MdAssessment, MdAssignment, MdAssignmentTurnedIn, MdCheck, MdCurtainsClosed, MdDangerous, MdDeleteSweep, MdDirectionsCar, MdEventAvailable, MdEventBusy, MdExitToApp, MdNavigation, MdPeople, MdSettings, MdTimer } from "react-icons/md";
import { REQUESTS_STATUS, REQUESTS_STATUS_ID, USER_POSITIONS } from "./constants";
import { DateTime } from "luxon";
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const MONTHS_LONG = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const saveSession = (session: ISession) => {
  sessionStorage.setItem('session', JSON.stringify(session));
}

export const getUserSession = (): ISession | null => {
  const session = sessionStorage.getItem("session") as unknown as string;
  return session && session != 'null' ? JSON.parse(session) as ISession : null;
}

export const saveToLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}
export const getStoredUserData = (): IUserData | null => {
  const data = localStorage.getItem('user') as unknown as string;
  return data && data != null && data != 'undefined' ? JSON.parse(data) as IUserData : null

}
export const getStatusMessage = (status: number): string => {
  switch (status) {
    default:
    case 0:
      return "Pending approval";
    case 1:
      return "Pending authorization";
    case 2:
      return "Already authorized";
    case 3:
      return "Request is closed";
    case 4:
      return "Request was rejected";
    case 5:
      return "Request was cancelled";

  }
}
export const clearSession = () => {
  localStorage.clear();
  sessionStorage.clear();
}
export const getIcon = (text: string | IconType): IconType => {
  let icon = MdAssignment;
  switch (text) {
    case 'apps':
      icon = MdApps;
      break;
    case 'people':
      icon = MdPeople;
      break;
    case 'settings':
      icon = MdSettings;
      break;
    case 'direction_cars':
      icon = MdDirectionsCar;
      break;
    case 'navigation':
      icon = MdNavigation;
      break;
    case 'assessment':
      icon = MdAssessment;
      break;
    case 'exit_to_app':
      icon = MdExitToApp;
      break;
    case 'assignment':
    default:
      icon = MdAssignment;
  }
  return icon;
}
export const dateFormatWithTime = (seconds: number, timeOnly: boolean) => {
  let months = MONTHS
  let dateObject = new Date(seconds * 1000);
  let hrs =
    dateObject.getHours() < 10
      ? "0" + dateObject.getHours()
      : dateObject.getHours();
  let mins =
    dateObject.getMinutes() < 10
      ? "0" + dateObject.getMinutes()
      : dateObject.getMinutes();
  let secs =
    dateObject.getSeconds() < 10
      ? "0" + dateObject.getSeconds()
      : dateObject.getSeconds();
  let date =
    dateObject.getDate() +
    " " +
    months[dateObject.getMonth()] +
    " " +
    dateObject.getFullYear();

  let time =
    " " +
    hrs +
    ":" +
    mins +
    ":" +
    secs;

  return timeOnly ? time : `${date}  ${time}`;
};
export const timeTraveled = (start: string | null | number | undefined, end: string | null | number | undefined): string => {
  if (start && end) {
    let endMilliseconds = 0;
    let startMilliseconds = 0;
    if (typeof start == 'string' && typeof end == 'string') {
      let date = new Date();
      let endtime = dateFormat(date.getTime() / 1000) + " " + end;
      let starttime = dateFormat(date.getTime() / 1000) + " " + start;
      let endDate = new Date(endtime);
      let startDate = new Date(starttime);
      endMilliseconds = endDate.getTime();
      startMilliseconds = startDate.getTime();
    }
    else {
      endMilliseconds = Number(end) * 1000;
      startMilliseconds = Number(start) * 1000;
    }

    let diff = 0;

    if (startMilliseconds > endMilliseconds) {
      diff = (24 * 3600 * 1000 - startMilliseconds + endMilliseconds) / 1000;
    } else {
      diff = (endMilliseconds - startMilliseconds) / 1000;
    }

    let hours = Math.floor(diff / 3600);
    let mins = Math.floor((diff % 3600) / 60);
    let secs = Math.floor((diff % 3600) % 60);
    let time = `${hours}h: ${mins}m: ${secs}s`;

    return time;
  }
  return 'N/A'
}
export const validatePhoneNumber = (phone: string): string | boolean => {
  let phoneNumber = phone ? phone.replaceAll(" ", "").replaceAll("-", "") : null;
  let validPhoneNumber: string | boolean = false;
  if (phoneNumber && phoneNumber.length > 9) {
    switch (phoneNumber.length) {
      case 10:
        validPhoneNumber = `255${phone.substr(1)}`; //trim leading 0 and prepend tz country prefix;
        break;
      case 12:
        validPhoneNumber = phoneNumber;
        break;
      case 13:
        if (phoneNumber.substr(0, 1) == '+') validPhoneNumber = phoneNumber.substr(1); //remove + sign and reassure country code
        break;
      default:
        validPhoneNumber = false;
        break;
    }
  }
  return validPhoneNumber;
};
export const getMonth=(month:number,long:boolean)=>{
 return long ? MONTHS_LONG.find((m:string,i:number)=> i==month): MONTHS.find((m:string,i:number)=> i==month)
}
export const dateFormat = (seconds: number): string => {
  let months = MONTHS;
  let dateObject = new Date(seconds * 1000);
  let date =
    dateObject.getDate() +
    " " +
    months[dateObject.getMonth()] +
    " " +
    dateObject.getFullYear();

  return date;
};
export const generateFuelOrderNumber = (request: IFuelRequest): string => {
  const date = DateTime.fromMillis(request.date_created * 1000).toJSDate();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear()
  return `FO-${year}${month < 10 ? `0${month}` : `${month}`}${day < 10 ? `0${day}` : `${day}`}-${request.id}`;
};
export const findTotalDistance = (collection: any[]): number => {
  let total = 0;
  collection.forEach((c) => {
    total += c.end_mileage - c.start_mileage;
  });
  return total;
};

export const findTotal = (collection: any[]): number => {
  let total = 0;
  collection.forEach((item) => {
    total += item.a_price * item.a_amount;
    // return total;
  });
  return total;
};
export const formatNumber = (num: number | string): string => {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 'N/A';
};

export const formatMoney = (num: number | string): string => {
  if (typeof num == 'string') num = Number(num)
  if (num > 1000000000) return `${(num / 1000000000).toFixed(1)}B`;
  if (num > 1000000) return `${(num / 1000000).toFixed(1)}M`;
  if (num > 1000) return `${(num / 1000).toFixed(1)}K`;
  return `${num}`
}

export const secondsToTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60
  return `${hours}h: ${minutes}m: ${secs}`
}

export const getQueryString = (url: string): string => {
  if (url && url.length > 4) {
    const parts = url.split("?");
    if (parts.length > 1) return `?${parts[1]}`;
    return ""
  }
  return ""

}
//check if string is only white spaces
//    export const isWhiteSpaces = (string:string):boolean => {
//       if (typeof string !== "string") return false;
//       let stringArray = Array.from(string);
//       let result = stringArray.splice(
//         0,
//         stringArray.length,
//         ...new Set<string>(stringArray)
//       );
//       if (result.length == 1) return result[0] === " ";
//       return false;
//     };

export const getPositionValue = (positions: any[], id: number): any => {
  let position = positions.filter((p) => {
    return p.id === id;
  });
  return position.length > 0
    ? position[0]
    : { id: id, value: "Unknown", positions: "" };
};

//validate email address
export const validateEmail = (email: string): boolean => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }

  return false;
};

//search username
export const userExists = (username: string, users: any[]): boolean => {
  let keyword = username.toLowerCase();
  users = users.filter((u) => {
    return u.username.toLowerCase().search(keyword) !== -1;
  });
  return users.length > 0;
};
//get activities
export const getActivities = (fcode: string, fundcodes: any[]): string[] => {
  if (fundcodes.length > 0) {
    let fc = fundcodes.filter((c) => {
      return c.code.toLowerCase() == fcode.toLowerCase();
    })[0];
    let activities = fc.activities.split(",");
    return activities;
  }
  return []
}

//get fundcodes from project code
export const getCodes = (project: string, fundcodes: any[]): any[] => {
  if (fundcodes.length > 0) {
    let codes = fundcodes.filter((fc) => {
      return fc.project === project;
    });
    return codes;
  } else return [];
};
export const getRequestTypeIdFromText = (text: string): number => {
  switch (text.toLowerCase()) {
    case 'vehilce':
      return 0;
    case "fuel":
      return 1;
    case 'service':
      return 2;
    case "full_service":
      return 3;
    case 'repair':
      return 4;
    default:
      return 0
  }
};
export const getRequestTypeText = (type: number) => {
  switch (type) {
    case 0:
      return "Vehicle Request";
    case 1:
      return "Fuel Request";
    case 2:
      return "Normal Car Service";
    case 3:
      return "Full Car Service";
    case 4:
      return "Repair Service";
  }
};

export const groupByArray = (xs: any[], key: any) => {
  let result = xs.reduce((rv, x) => {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r: any) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);

  return result;
};

export const calculateTime = (end: string, start: string): CustomTime => {
  let date = new Date();
  let endtime = dateFormat(date.getTime() / 1000) + " " + end;
  let starttime = dateFormat(date.getTime() / 1000) + " " + start;
  let endDate = new Date(endtime);
  let startDate = new Date(starttime);
  let endMilliseconds = endDate.getTime();
  let startMilliseconds = startDate.getTime();

  let diff = 0;

  if (startMilliseconds > endMilliseconds) {
    diff = (24 * 3600 * 1000 - startMilliseconds + endMilliseconds) / 1000;
  } else {
    diff = (endMilliseconds - startMilliseconds) / 1000;
  }
  const difference = diff / 3600
  let hours = parseInt(difference.toString());
  let mins = parseInt((difference / 60).toString());
  let secs = parseInt((difference % 60).toString());
  let time = { h: hours, m: mins, s: secs };

  return time;
};
export const isEmailAvailable = (users: any[], email: string): boolean => {
  let user = users.filter((u) => {
    return u.email === email;
  });
  if (user && user.length > 0) return false;
  else return true;
};
export const getStatusBadge = (id: number,type:string): IRequestStatusBadge => {
  let result: any = {};
  switch (id) {
    default:
    case 0:
      result.icon = MdEventAvailable;
      result.text = 'Available';
      result.color = 'text-green-700'
      break;
    case 1:
      result.icon = MdEventBusy;
      result.text = type == 'vehicle' ? 'In Use':'Assigned';
      result.color = 'text-accent'
      break;
    case 3:
      result.icon = MdDeleteSweep;
      result.text = 'Deleted';
      result.color = 'text-red-500'
      break;
  }
  return result;
}

export const getRequestStatus = (id: number): IRequestStatusBadge => {
  let result: any = {};
  switch (id) {
    default:
    case 0:
      result.icon = MdTimer;
      result.text = 'Pending';
      result.color = 'text-defaultText'
      break;
    case 1:
      result.icon = MdApproval;
      result.text = 'Approved';
      result.color = 'text-accent'
      break;
    case 2:
      result.icon = MdAssignmentTurnedIn;
      result.text = 'Authorized';
      result.color = 'text-green-700'
      break;
    case 3:
      result.icon = MdCurtainsClosed;
      result.text = 'Closed';
      result.color = 'text-gray-600'
      break;
    case 4:
      result.icon = MdDangerous;
      result.text = 'Rejected';
      result.color = 'text-red-500'
      break;
    case 5:
      result.icon = MdDeleteSweep;
      result.text = 'Cancelled';
      result.color = 'text-gray-300'
      break;
  }
  return result;
}
export const canAuthorize = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return (user.id == request.admin || user.position_detail?.value == USER_POSITIONS.SUPER_ADMIN)
}
export const canApprove = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return (user.id == request.approver || user.id == request.admin || user.position_detail?.value == USER_POSITIONS.SUPER_ADMIN)
}
export const canEdit = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return (isRequestor(user, request) || canApprove(user, request)) && [REQUESTS_STATUS_ID.PENDING, REQUESTS_STATUS_ID.APPROVED, REQUESTS_STATUS_ID.AUTHORIZED].includes(request.status)
}
export const isRequestor = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return user.id == request.requestor
}
export const isRequestApprover = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return user.id == request.approver || user.position_detail?.value == USER_POSITIONS.SUPER_ADMIN
}
export const canViewRequest = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return user.id == request.requestor || user.position_detail?.value == USER_POSITIONS.SUPER_ADMIN || user.id == request.approver || user.id == request.admin || user.id == request.driver
}
export const isRequestAuthorizer = (user: User | IUserData | null, request: IRequest | IFuelRequest | IMaintenanceRequest): boolean => {
  if (user == null) return false;
  return user.id == request.admin || user.position_detail?.value == USER_POSITIONS.SUPER_ADMIN
}
export const getVehicleRequestors = (users: User[]): User[] => {
  if (users && users.length > 0) {
    return users.filter((user: User) => user.position_detail?.value != USER_POSITIONS.DRIVER)
  }
  return []
}
export const isAdmin = (user: User | null): boolean => {
  if (user) {
    return user?.position_detail?.value == USER_POSITIONS.ADMIN || user?.position_detail?.value == USER_POSITIONS.SUPER_ADMIN
  }
  return false;
}

export const isDriver = (user: User | IUserData | null): boolean => {
  return user?.position_detail?.value == USER_POSITIONS.DRIVER
}


export const isAuthenticated = () => {
  const session = getUserSession();
  if (session?.accessToken) {
    const userData = getStoredUserData();
    if (userData) return true;
    return false
  }
  return false;
}
export const makeYears = (range:number): number[]=>{
  const currentYear = (new Date()).getFullYear();
  if(range < 1) range = 10;
  let result: number[] = []
  for(let i=0; i<range;i++){
    result.push(currentYear - i)
  }
  return result;
}