
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getStoredUserData, dateFormat, getQueryString, getRequestStatus, canApprove, canAuthorize, canEdit, canViewRequest, getUserSession, isAuthenticated } from "../../utils/utils";
import { ApiClient } from "../../utils/apiclient";
import { IRequest, User, Vehicle } from "../../utils/types";
import Sidebar from "../../components/sidebar";
import Image from "../../components/image";
import { MdClose } from "react-icons/md";
import { CareVehicleImage } from "../../components/image-assets";
import RequestStatusBadge from "../../components/request_status";
import { REQUESTS_STATUS, REQUESTS_STATUS_ID } from "../../utils/constants";
import Spinner from "../../components/spinner";
import { toast, ToastContainer } from "react-toastify";
import { RequestService } from "../../utils/services/request.service";
import TopStrip from "../../components/top_strip";

const VehicleRequestView = (props: any) => {
    const userData = getStoredUserData()
    const userSession = getUserSession()
    const client = new ApiClient(userSession?.accessToken);
    const requestService = new RequestService(client)
    const params = useParams();
    const query: URLSearchParams = useSearchParams()[0];
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const navigate = useNavigate()
    const location = useLocation();

    const requestItem = location.state?.request;
    const [request, setRequest] = useState<IRequest>(requestItem)
    const [comment, setComment] = useState<string>('')
    const [drivers, setDrivers] = useState<User[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([])
    const [assignedVehicle, setAssignedVehicle] = useState<number>()
    const [assignedDriver, setAssignedDriver] = useState<number>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleCancel = () => {
        navigate('/requests', { replace: false })
    }
    const getRequestDetails = async (reqId: number, reqType: number) => {
        console.log('fetching....')
        setIsLoading(true)
        // const type = getRequestTypeIdFromText(reqType)
        const axios = await requestService.getRequestDetails(reqId, reqType);
        if (axios.status == 200) {
            const req = axios.data.request
            setRequest(req);
            setIsLoading(false);
            loadVehicleAndDrivers(req)
        }
    }
    const showNotifications = () => {

    }
    const getStatusBadge = () => {

    }
    const sendApproval = async () => {
        if (userData) {
            const data = {
                ...request,
                remark: comment
            }
            setIsLoading(true)
            try {
                const axios = await requestService.processRequest(data, userData, true)
            }
            catch (e: any) {
                console.log("🚀 ~ sendApproval ~ e:", e)
                alert("Something went wrong")
            }
            finally {
                getRequestDetails(request.id, request.type)
                setIsLoading(false)
            }
        }

    }
    const authorizeRequest = async () => {
        if (userData && assignedDriver && assignedDriver != 0 && assignedVehicle && assignedVehicle != 0) {
            const data = {
                ...request,
                remark1: comment,
                driver: assignedDriver!,
                vehicle_id: assignedVehicle!,
                attenderName: `${userData.fname} ${userData.lname}`
            }
            setIsLoading(true)
            try {
                const response = await requestService.authorizeRequest(data, userData)
                if (response.status in [200, 201]) {
                    toast.success(response.data, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    setTimeout(() => {
                        navigate(-1)
                    }, 5000);

                }
            }
            catch (e: any) {
                console.log("🚀 ~ authorizeRequest ~ e:", e)
                toast.error(e, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })

            }
            finally {
                getRequestDetails(request.id, request.type)
                setIsLoading(false)
            }
        }
        else alert(`Please assign a driver and a vehicle`)
    }
    const rejectRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                remark: comment,
                attended_by: userData.id,
                closed_by: userData.id
            }
            setIsLoading(true)
            try {
                const axios = await requestService.processRequest(data, userData, false)
                console.log("🚀 ~ sendApproval ~ axios:", axios.data)

            }
            catch (e: any) {
                console.log("🚀 ~ sendApproval ~ e:", e)
                alert("Something went wrong")
            }
            finally {
                getRequestDetails(request.id, request.type)
                setIsLoading(false)
            }
        }

    }
    const getComment = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value) {
            setComment(e.currentTarget.value.trim())
        }
    }
    const handleVehicleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const vehicleId = Number(e.currentTarget.value);
        setAssignedVehicle(vehicleId);
    }
    const handleDriverSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const driverId = Number(e.currentTarget.value);
        setAssignedDriver(driverId);
    }
    const loadVehicleAndDrivers = async (request: IRequest) => {
        const [drivers, vehicles] = await Promise.all([client.getAvailableDrivers(request), client.getAvailableVehicles(request)]);
        console.log("🚀 ~ getRequestDetails ~ drivers:", drivers, vehicles)
        setDrivers(drivers);
        setVehicles(vehicles)
    }
    const handleRefresh = () => {
        // const id = Number(params.reqId);
        //     const type = Number(query.get('t'));
        //     if(window.performance){
        //         if(performance.navigation.type == 1){
        //             // getRequestDetails(id,type)
        //             alert('page refreshed')
        //         }
        //     }
    }
    useEffect(() => {
        if (isAuthenticated()) {
            const id = Number(params.reqId);
            const type = Number(query.get('t'));
            if (!request || request == null || request == undefined) getRequestDetails(id, type)
            else loadVehicleAndDrivers(request)
        }
        else navigate('/login', { state: { targetPath: target } });

    }, [request])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            <ToastContainer />
            {request && canViewRequest(userData, request) ?
                <div className="md:w-9/12 mx-auto space-y-2 mt-2">
                    {isLoading ? <Spinner className="spinner-md mt-6" /> :
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between p2-4">
                                <p className="text-2xl font-bold">Vehicle Request</p>
                            </div>
                            <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                                <div className="p-4 w-full md:w-4/12 text-center">
                                    <Image src={CareVehicleImage} className="w-6/12 mx-auto" />
                                    <div className="m-4 text-1xl mx-auto "><RequestStatusBadge status={getRequestStatus(request.status)} /></div>
                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Date Requested: </label>
                                        <p className="text-1xl">{`${dateFormat(request.date_created)}`}</p>
                                    </div>

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Start Location: </label>
                                        <p className="text-1xl">{`${request.start_location}, ${request.start_city}`}</p>
                                    </div>


                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Purpose: </label>
                                        <p className="text-1xl">{`${request.purpose}`}</p>
                                    </div>

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Requestor: </label>
                                        <p className="text-1xl">{`${request.nameOfRequestor}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        {canAuthorize(userData, request) && request.statusText == REQUESTS_STATUS.APPROVED ? <>
                                            <label className="text-gray-500">Assign a Vehicle: </label>
                                            <select name="ass_driver" id="ass_driver" className="form-control md:w-9/12 p-2" onChange={handleVehicleSelect}><option value='0'>--select--</option>
                                                {vehicles.map((vehicle: Vehicle) => {
                                                    return <option key={vehicle.id} value={vehicle.id}>{`${vehicle.pnumber}`}</option>
                                                })}
                                            </select>
                                        </>
                                            : <>
                                                <label className="text-gray-500">Assigned Vehicle: </label>
                                                <p className="text-1xl">{`${request.vehicle_id ? request.vehicle : 'N/A'}`}</p></>}
                                    </div>

                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Date Required: </label>
                                        <p className="text-1xl">{`${dateFormat(request.start_time)}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Destination: </label>
                                        <p className="text-1xl">{`${request.destination}, ${request.destination_city}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Expected Return: </label>
                                        <p className="text-1xl">{`${dateFormat(request.end_time)}`}</p>
                                    </div>

                                    <div className="text-start mb-4">

                                        {canAuthorize(userData, request) && request.statusText == REQUESTS_STATUS.APPROVED ? <>
                                            <label className="text-gray-500">Assign a Driver: </label>
                                            <select name="ass_driver" id="ass_driver" className="form-control md:w-9/12 p-2" onChange={handleDriverSelect}><option value='0'>--select--</option>
                                                {drivers.map((driver: User) => {
                                                    return <option key={driver.id} value={driver.id}>{`${driver.fname} ${driver.lname}`}</option>
                                                })}
                                            </select></>
                                            : <><label className="text-gray-500">Assigned Driver: </label>
                                                <p className="text-1xl">{`${request.driver ? request.nameOfDriver : 'N/A'}`}</p>
                                            </>}
                                    </div>

                                </div>
                                <div className="m-4 flex-col justify-between items-end h-full space-y-5">
                                    <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/requests') }} />
                                    {/* {canEdit(userData, request) ? <MdEdit className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/users/edit', { state: { request: request } }) }} /> : null} */}
                                </div>
                            </div>
                            <div className="w-full flex justify-start items-start mt-5 py-4 px-2 flex-wrap border mx-auto">
                                <div className="text-start mb-4 w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Project: </label>
                                    <p className="text-1xl">{`${request.project}`}</p>
                                </div>
                                <div className="text-start mb-4 w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Fund Code: </label>
                                    <p className="text-1xl">{`${request.fundcode}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Account and source  </label>
                                    <p className="text-1xl">{`${request.account}, ${request.source}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Category/Activity: </label>
                                    <p className="text-1xl">{`${request.category}/ ${request.activity}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Approver: </label>
                                    <p className="text-1xl">{request.nameOfDefaultApprover}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Admin: </label>
                                    <p className="text-1xl">{`${request.nameOfAdmin}`}</p>
                                </div>
                                {request.statusText == REQUESTS_STATUS.APPROVED ?
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div> : null}
                                {request.statusText == REQUESTS_STATUS.AUTHORIZED ? <>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Authorized by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div></> : null}
                                {request.statusText == REQUESTS_STATUS.REJECTED ?
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Rejected by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div> : null}
                                {request.statusText == REQUESTS_STATUS.CLOSED ? <>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Authorized by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Closed by: </label>
                                        <p className="text-1xl">{request.nameOfCloser}</p>
                                    </div></> : null}
                                {request.status == REQUESTS_STATUS_ID.APPROVED && request.comment ? <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Comment: </label>
                                    <p className="text-1xl">{request.comment}</p>
                                </div> : null}
                            </div>

                            <div className="w-full flex-column items-start justify-center mt-5 p-4 border mx-auto">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Remarks </label>
                                    {request.comment ? <p className="text-sm">{request.nameOfRequestor}: {request.comment ? request.comment : 'None'}</p> : null}
                                    {request.remark ? <p className="text-sm">{request.nameOfApprover}: {request.remark ? request.remark : 'None'}</p> : null}
                                    {request.remark1 ? <p className="text-sm">{request.nameOfAttender}: {request.remark1 ? request.remark1 : 'None'}</p> : null}
                                </div>
                                {(canApprove(userData, request) || canAuthorize(userData, request) || canEdit(userData, request)) && request.status in [REQUESTS_STATUS_ID.PENDING, REQUESTS_STATUS_ID.APPROVED, REQUESTS_STATUS_ID.AUTHORIZED] ?
                                    <input type="text" name="comment" className="form-control p-2" placeholder="write your comment or remarks" onChange={getComment} /> : null
                                }
                                <div className="flex justify-end items-center space-x-5 mt-1">

                                    {canApprove(userData, request) && request.statusText == REQUESTS_STATUS.PENDING ? <button className="bg-green-700 text-white " onClick={sendApproval}>Approve</button> : null}
                                    {canAuthorize(userData, request) && request.statusText == REQUESTS_STATUS.APPROVED ? <button className="bg-green-700 text-white " onClick={authorizeRequest}>Authorize</button> : null}
                                    {canEdit(userData, request) ? <button className="bg-red-600 text-white" onClick={rejectRequest}>{userData?.id == request.requestor ? 'Cancel' : 'Reject'}</button> : null}

                                </div>
                            </div>

                        </div>}
                </div> : <p className="text-center text-2xl mx-auto">You can not view this data</p>}
        </main></>);
}
export default VehicleRequestView;