import { ISummaryCard } from "../utils/types"



const SummaryCard = (props: ISummaryCard)=>{

    return(<div className={`h-25 md:w-4/12 w-full m-2 cursor-pointer flex justify-start items-center py-6 px-4 shadow-md rounded-md md:space-x-2 hover:bg-orange-500 bg-accent text-white`}>
        <p className="md:text-2xl w-3/6">{props.value}</p>
        <p className="text-sm text-start">{props.title}</p>
    </div>)
}

export default SummaryCard;