import { AxiosError } from "axios";
import { ApiClient } from "../apiclient";
import { ApiResponse, IFuelRequest, IMaintenanceRequest, IPaginationOptions, IRequest, IUserData, User } from "../types";
import { REQUEST_TYPES, REQUESTS_STATUS, REQUESTS_STATUS_ID } from "../constants";

export class ReportService {
    client!: ApiClient;
    constructor(client: ApiClient) {
        this.client = client;
    }

    getMonthlySummary = async (): Promise<ApiResponse>=>{
        
        try {
            const response = await this.client.get(`/reports/monthly-summary`);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data,
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    getFuelReport = async (startDate:number,endDate:number): Promise<ApiResponse> =>{
        try{
            const response = await this.client.get(`/reports/fuel?startDate=${startDate}&endDate=${endDate}`);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data.report,
            }
        }
        catch(e:any){
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    getMaintenanceReport = async (startDate:number,endDate:number): Promise<ApiResponse> =>{
        try{
            const response = await this.client.get(`/reports/maintenance?startDate=${startDate}&endDate=${endDate}`);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data.report,
            }
        }
        catch(e:any){
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    getSMSReport = async (startDate:number,endDate:number): Promise<ApiResponse> =>{
        try{
            const response = await this.client.get(`/reports/sms?startDate=${startDate}&endDate=${endDate}`);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data.report,
            }
        }
        catch(e:any){
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
}