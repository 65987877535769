import { useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationView from "../../components/notification_view";
import Sidebar from "../../components/sidebar";
import { Trip, User } from "../../utils/types";
import { formatNumber, getQueryString, getRequestStatus, getStatusBadge, getStoredUserData, getUserSession, isAdmin } from "../../utils/utils";
import Image from "../../components/image";
import { CareAvatarImage } from "../../components/image-assets";
import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { ApiClient } from "../../utils/apiclient";
import { useEffect, useState } from "react";
import TripsTable from "../trips/trips_table";
import { AxiosError } from "axios";
import { request } from "http";
import RequestStatusBadge from "../../components/request_status";
import TopStrip from "../../components/top_strip";

const UserView = (props: any) => {
    const params = useParams();
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const userSession = getUserSession();
    console.log("🚀 ~ UserView ~ target:", target)
    const client = new ApiClient(userSession?.accessToken);
    const userData = getStoredUserData();
    const navigate = useNavigate()
    const location = useLocation();
    const state = location.state;
    const [user, setUser] = useState<User>(state?.user ? state.user : null);
    const [userTrips, setUserTrips] = useState<Trip[]>([])


    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    const getTrips = async () => {
        console.log("🚀 ~ getTrips ~ req:", client.ACCESS_TOKEN)
        try {
            const req = await client.getDriverTrips(user.id, { paginate: false })
            setUserTrips(req.data.trips)
        }
        catch (e: any) {
            if (e instanceof AxiosError) {
                if (e.status == 401) {
                    alert(e.response?.data.error)
                    navigate('/login', { state: { targetPath: target } })
                }
            }
            setUserTrips([])
        }
    }
    useEffect(() => {
        console.log(state.user)
        if (userData && userSession) {

            if (!user) {
                console.log(params)
                const id = Number(params.userId);
                getUserDetails(id)

            }
            else {
                getTrips()
            }
        }
        else navigate('/login', { state: { targetPath: target } })
    }, [])
    const getUserDetails = async (userId: number) => {
        console.log("🚀 ~ getUserDetails ~ userId:", userId)

        const user = await client.getUserDetails(userId);
        console.log("🚀 ~ getUserDetails ~ user:", user.data.user)
        getTrips();
        setUser(user.data.user)
    }
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={2} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>

            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="w-full">
                    <div className="w-full flex items-center justify-between p2-4">
                        <p className="text-2xl font-bold">User Details</p>
                    </div>
                    {user ? <>
                        <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                            <div className="p-4 w-full md:w-6/12">
                                <Image src={CareAvatarImage} className="h-40 mx-auto" />
                                <div className="w-full flex justify-center items-center space-x-5 mt-5 py-4">
                                    <p className="m-4 text-1xl mx-auto "><RequestStatusBadge status={getStatusBadge(user.busy, 'user')} /></p>
                                </div>
                            </div>
                            <div className="ms-5 md:w-6/12">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">First Name: </label>
                                    <p className="text-1xl">{user.fname}</p>
                                </div>
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Email Address: </label>
                                    <p className="text-1xl">{`${user.email}`}</p>
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Department: </label>
                                    <p className="text-1xl">{`${user.department_detail?.code}`}</p>
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Location: </label>
                                    <p className="text-1xl">{`${user.location}`}</p>
                                </div>

                            </div>
                            <div className="ms-5 md:w-6/12">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Last Name: </label>
                                    <p className="text-1xl">{user.lname}</p>
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Phone Number: </label>
                                    <p className="text-1xl">{`${user.phone}`}</p>
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Position: </label>
                                    <p className="text-1xl">{`${user.position_detail?.value}`}</p>
                                </div>


                            </div>
                            <div className="m-4 flex-col justify-between items-end h-full space-y-4">
                                <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/users') }} />
                                {isAdmin(userData) ? <MdEdit className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate(`/users/edit/${user.id}`, { state: { user: user } }) }} /> : null}
                            </div>
                        </div>
                        <div className="w-full mx-auto mt-5 py-4">
                            {userTrips ?
                                <><div className="text-textDefault text-1xl py-2 flex justify-between items-center">
                                    <span className="font-bold text-center ">Trips Taken: {userTrips.length}</span>
                                    <span className="font-bold text-center">Distance Traveled: {formatNumber(userTrips.reduce((total: number, initialValue: Trip) => total + (initialValue.end_mileage!! - initialValue.start_mileage), 0))} Km</span>
                                </div>
                                    <TripsTable data={userTrips} /></>
                                : null}
                        </div>
                    </> : <span>Could not find user data</span>}
                </div>
            </div>
        </main>
    </>)
}
export default UserView;