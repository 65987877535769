
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import UserListItem from "./users_table";
import { getStoredUserData } from "../../utils/utils";
import NotificationView from "../../components/notification_view";
import { useNavigate } from "react-router-dom";
import { ApiClient } from "../../utils/apiclient";
import SearchBox from "../../components/search_box";
import PageControl from "../../components/page_control";
import Spinner from "../../components/spinner";
import { User } from "../../utils/types";
import TopStrip from "../../components/top_strip";

const Users = (_props: any) => {
  const navigate = useNavigate();
  const notifications = []
  const userData = getStoredUserData();

  const [activeUsers, setActiveUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>(activeUsers)
  const [pageInfo, setPageInfo] = useState<any>({ totalPages: 0, totalCount: 0, currentPage: 1, pageSize: 15, hasNext: false, hasPrevious: false });
  const [pageSize, setPageSize] = useState<number>(20)
  const [page, setPage] = useState<number>(1)

  const showNotifications = () => {
    navigate(`/notifications/${_props.user?.id}`)
  }
  const handleClickNew = () => {
    navigate('/users/new')
  }
  const getPreviousPage = () => {
    setPage(page - 1)
  }
  const getNextPage = () => {
    setPage(page + 1);
  }
  const getUsers = async (pageSize: number, page: number) => {
    const apiClient = new ApiClient();
    const response = await apiClient.getUsers({ page: page, take: pageSize });
    if (response.status == 200) {
      const data = response.data;
      setPageInfo({ totalPages: response.data.totalPages, totalCount: response.data.totalCount, currentPage: response.data.page, pageSize: response.data.pageSize, hasNext: response.data.hasNext, hasPrevious: response.data.hasPrevious })

      setActiveUsers(data.users);
      setFilteredUsers(data.users);
    }
  }

  const handlePageSizeChange = (pageSize: number) => {
    if (pageSize && pageSize >= 10) {
      setPageSize(pageSize);
      setPage(1)
    }
    else {
      setPageSize(10);
      setPage(1);
    }
  }


  const handleFilter = async (keyword: string) => {
    let result = activeUsers.filter((user) => user?.fname.toLowerCase().includes(keyword.toLowerCase())
      || user?.lname.toLowerCase().includes(keyword.toLowerCase())
      || user?.username?.toLowerCase().includes(keyword.toLowerCase())
      || user?.position_detail?.value.toLowerCase().includes(keyword.toLowerCase())
      || user?.region.toLowerCase().includes(keyword.toLowerCase())
      || user?.email.toLowerCase().includes(keyword.toLowerCase())
      || user?.phone?.toLowerCase().includes(keyword.toLowerCase())
      || user?.location?.toLowerCase().includes(keyword.toLowerCase())
      || user?.dept_code?.toLowerCase().includes(keyword.toLowerCase()));
    setFilteredUsers(result);
  }
  useEffect(() => {
    getUsers(pageSize, page)
  }, [pageSize, page])
  return (<>
    <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
      <TopStrip user={userData} />
    </div>
    <main className="w-full flex bg-white min-h-full">

      <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
        <Sidebar notificationCount={notifications.length} user={userData} active={2} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
      </div>

      <div className="md:w-9/12 mx-auto space-y-4 mt-2">

        <div className="flex space-x-2 w-full my-2 pb-2 items-center justify-between border-b-2">
          <div className="w-full flex items-center justify-start space-x-2">
            <button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12" onClick={handleClickNew}>New User</button>
            <button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12">Restore User</button>
            <button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12">Upload Phone</button>
          </div>
        </div>
        <div className="w-full text-textDefault mt-2">
          <div className="w-full flex items-center justify-between pt-2">
            <p className="text-2xl font-bold">Users</p>
            <SearchBox onChange={handleFilter} /></div>
          <div className="w-full border-top flex-row flex-start bg-main-color align-items-center ">
            <PageControl pageSize={pageSize} onNext={getNextPage} onPrevious={getPreviousPage} currentPage={page} totalPages={pageInfo.totalPages} onPageSizeChange={(pageSize: number) => { handlePageSizeChange(pageSize) }} hasNext={pageInfo.hasNext} hasPrevious={pageInfo.hasPrevious} />
          </div>
          <UserListItem users={filteredUsers} />
        </div>
      </div>
    </main></>
  );
}

export default Users;