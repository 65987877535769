import { useNavigate } from "react-router-dom"
import TableHeader from "../../components/table_header"
import { DEPARTMENTS_HEADINGS, PROJECTS_HEADINGS } from "../../utils/constants"
import { IDepartmentDetail, IProjectDetail, Trip } from "../../utils/types"
import { formatNumber, dateFormat } from "../../utils/utils"

const ProjectTable = (props:any)=>{
    const navigate = useNavigate()
    return (
    
        <table className="w-full text-left p-2 mx-auto border">
          <TableHeader data={PROJECTS_HEADINGS} />
          <tbody className="w-full text-sm">
            {props.data && props.data.map((row:IProjectDetail) => {
              return (
                <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={()=>{navigate(`/settings/projects/view/${row.id}`,{state:{project:row}})}}>
                  <td className="border-bottom px-1 py-2">{row.name}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.code}`}
                  </td>
                  {/* <td className="border-bottom px-1 py-2">{row.nameOfRequestor}
                  </td> */}
                  <td className="border-bottom px-1 py-2">{`${row.donor}`}</td>
                  <td className="border-bottom px-1 py-2">{row.nameOfCoordinator}
                  </td>
                  <td className="border-bottom px-1 py-2">{row.department_detail?.code}
                  </td>
                  <td className="border-bottom px-1 py-2">{row.status}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        )
}
export default ProjectTable