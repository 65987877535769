import React from 'react';
import MainRouter from './routes'
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

function App() {
  return (
   <div className='App'><MainRouter /></div>
  );
}

export default App;
