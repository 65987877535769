import { useEffect, useState } from "react"
import { MdFileDownload } from "react-icons/md"
import { isAuthenticated, getUserSession, formatMoney } from "../../utils/utils"
import { ApiClient } from "../../utils/apiclient"
import { useNavigate } from "react-router-dom"
import { ReportService } from "../../utils/services/report.service"
import ReactApexChart from "react-apexcharts";
import { DateTime } from "luxon"
import DateSelectorDialog from "./date_picker_dialog"
import exportFromJson from 'export-from-json'
import VehicleMaintenanceTable from "./vehicle_maintenance_table"
import Spinner from "../../components/spinner"
import Tooltip from "@material-ui/core/tooltip"
import CalendarView from "@material-ui/icons/CalendarTodaySharp"

const MaintenanceReport = () => {
    const session = getUserSession();
    const apiClient = new ApiClient(session?.accessToken)
    const reportService = new ReportService(apiClient)
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState<number>(DateTime.fromMillis(Date.now()).minus({ month: 1 }).toMillis())
    const [endDate, setEndDate] = useState<number>(Date.now())
    const [reports, setReports] = useState<any>();
    const [seriesDistance, setSeriesDistance] = useState<any[]>([])
    const [seriesCost, setSeriesCost] = useState<any[]>([])
    const [vehicles, setVehicles] = useState<string[]>([])
    const [optionsDistance, setOptionsDistance] = useState<any>()
    const [optionsCost, setOptionsCost] = useState<any>()
    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const getMaintenanceReports = async (startDate: number, endDate: number) => {
        setLoading(true)
        const reports = await reportService.getMaintenanceReport(startDate, endDate);
        const vehicleList = reports.data?.vehicles
        const optionsF = {
            chart: {
                id: 'maintenance-report',
                toolbar: { show: true }

            },
            title: {
                text: 'Cost of Maintenance by Vehicle'
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return val + "K"
                    }
                },
                position: 'top'
            },
            xaxis: {
                categories: vehicleList
            }
        }
        const options = {
            chart: {
                id: 'distance-report',
                toolbar: { show: true }

            },
            title: {
                text: 'Distance Covered by Vehicle'
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return val + "K"
                    }
                },
                position: 'top'
            },
            xaxis: {
                categories: vehicleList
            }
        }
        const series = [{
            name: "Distance",
            data: reports.data.vehicleMaintenance.map((d: any) => (d.distance / 100).toFixed(1))
        },
        ]

        setSeriesDistance(series)
        setSeriesCost([{
            name: "Cost",
            data: reports.data.vehicleMaintenance.map((d: any) => (d.cost / 1000).toFixed(1))
        }])
        setVehicles(vehicleList)
        setOptionsDistance(options)
        setOptionsCost(optionsF)
        setReports(reports.data)
        setLoading(false)
    }
    const handleCalendarClick = () => {
        setOpen(true)
    }
    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login', { state: { targetPath: window.location.pathname } })
        }
        else {
            getMaintenanceReports(startDate, endDate);
        }

    }, [])
    const handleSubmit = (formData: any) => {
        setStartDate(Date.parse(formData.startDate))
        setEndDate(Date.parse(formData.endDate))
    }
    const downloadFile = () => {
        const data = reports?.vehicleMaintenance
        const fileName = 'maintenance-performance';
        const exportType = 'csv';
        exportFromJson({ data, fileName, exportType })
    }
    return (<>
        <div className="w-full">
            {isAuthenticated() ?
                <div className="w-full flex flex-col justify-start items-start mx-auto border p-4">
                    {open ? <DateSelectorDialog state={open} onClose={() => { setOpen(false) }} onSubmit={handleSubmit} /> : null}
                    {loading ? <Spinner className="spinner-md mt-6" /> : <><div className="flex justify-between items-center space-x-4"><p className="text-md">{`Report for ${DateTime.fromMillis(startDate).toLocaleString(DateTime.DATETIME_MED)} - ${DateTime.fromMillis(endDate).toLocaleString(DateTime.DATETIME_MED)}`}</p>
                    <Tooltip title="Select date">
                        <CalendarView className="text-accent text-1xl cursor-pointer" onClick={handleCalendarClick} />
                    </Tooltip>
                    </div>
                        {reports ? <div className="w-full flex justify-start space-x-4 items-start mx-auto text-gray-500 py-4 text-sm border-b border-b-gray-400">
                            <span>Total Cost: <span className="font-bold">{formatMoney(reports.totalMaintenanceCost)} Tsh</span></span>
                            <span>Avg. Cost per Vehicle:  <span className="font-bold">{formatMoney(reports.averageCostPerVehicle)} Tsh</span></span>
                            <span>Avg. Cost per Distance:  <span className="font-bold">{formatMoney(reports.averageCostPerDistance)} Tsh/Km</span></span>
                            {/* <span>Average Consumption:  <span className="font-bold">{`${reports.averageConsumption} Km/L`}</span></span> */}
                        </div> : null}
                        <div className="w-full flex justify-start items-start space-x-2 mt-4">
                            <div className="w-6/12">
                                {optionsDistance && seriesDistance && vehicles ? <ReactApexChart type="area" options={optionsDistance} series={seriesDistance} /> : null}
                            </div>
                            <div className="w-6/12">
                                {optionsCost && seriesCost ? <ReactApexChart type="line" options={optionsCost} series={seriesCost} /> : null}
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <div className="w-full flex justify-between items-center">
                                <p>Maintenance Performance Reports by Vehicle</p>
                                <MdFileDownload className="text-2xl text-accent cursor-pointer" onClick={downloadFile} />
                            </div>
                            <VehicleMaintenanceTable data={reports?.vehicleMaintenance} />
                        </div>
                    </>}</div>

                : <span>You need to login</span>}
        </div >
    </>)
}
export default MaintenanceReport;