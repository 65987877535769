import { useNavigate } from "react-router-dom"
import TableHeader from "../../components/table_header"
import { DEPARTMENTS_HEADINGS } from "../../utils/constants"
import { IDepartmentDetail, Trip } from "../../utils/types"
import { formatNumber, dateFormat } from "../../utils/utils"

const DepartmentTable = (props:any)=>{
    const navigate = useNavigate()
    return (
    
        <table className="w-full text-left p-2 mx-auto border">
          <TableHeader data={DEPARTMENTS_HEADINGS} />
          <tbody className="w-full text-sm">
            {props.data && props.data.map((row:IDepartmentDetail) => {
              return (
                <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={()=>{navigate(`/settings/departments/view/${row.id}`,{state:{department:row}})}}>
                  <td className="border-bottom px-1 py-2">{row.code}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.description}`}
                  </td>
                  {/* <td className="border-bottom px-1 py-2">{row.nameOfRequestor}
                  </td> */}
                  <td className="border-bottom px-1 py-2">{`${row.region}`}</td>
                  <td className="border-bottom px-1 py-2">{row.nameOfAdmin}
                  </td>
                 
                  
                </tr>
              )
            })}
          </tbody>
        </table>
        )
}
export default DepartmentTable