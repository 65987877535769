import { AxiosError } from "axios";
import { ApiClient } from "../apiclient";
import { ApiResponse, IFuelRequest, IMaintenanceRequest, IPaginationOptions, IRequest, IUserData, User } from "../types";
import { REQUESTS_STATUS_ID } from "../constants";

export class RequestService {
    client!: ApiClient;
    constructor(client: ApiClient) {
        this.client = client;
    }

    getRequests = async (options?: IPaginationOptions) => {
        const page = options && options.page || 1;
        const take = options && options.take || 20
        const requests = await this.client.get(`/requests?page=${page}&take=${take}`);
        return requests;
    }
    getUserRequests = async (userId: number, options?: IPaginationOptions) => {
        const page = options && options.page || 1;
        const take = options && options.take || 20
        const requests = await this.client.get(`/user/requests/${userId}?page=${page}&take=${take}`);
        return requests;
    }
    
    getRequestDetails = async (requestId: number, requestType: number) => {
        const request = await this.client.get(`/request/${requestId}/${requestType}`);
        console.log("🚀 ~ RequestService ~ getRequestDetails= ~ request:", request)
        return request;
    }
    
    getLastRequest = async (vehicle: string, type: number): Promise<any> => {
        const axios = await this.client.get(`/last_request/${vehicle}?type=${type}`);
        return axios;
    }
    createRequest = async (body: IRequest | IFuelRequest): Promise<ApiResponse> => {
        try {
            const response = await this.client.post(`/request`, body);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }

    }
    createServiceRequest = async (body: Partial<IMaintenanceRequest>): Promise<ApiResponse> => {
            const reqBody = {
                admin:body.admin,
                type: body.type,
                requestType: body.type,
                requestor: body.requestor,
                nameOfRequestor: body.nameOfRequestor,
                vehicle: body.vehicle,
                mileage: body.mileage,
                project: body.project,
                fundcode: body.fundcode,
                account: body.account,
                activity: body.activity,
                source: body.source,
                category: body.category,
                status: REQUESTS_STATUS_ID.PENDING,
                approver: body.approver,
                start_time: body.start_time,
                parts: body.parts,
                cost: body.cost,
                supplier: body.supplier,
                description:body.description,
                distribution:body.distribution

            }
        try {
            const response = await this.client.post(`/request`, reqBody);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }

    }
    authorizeRequest = async (request: IRequest | IFuelRequest | IMaintenanceRequest, user: User | IUserData, data?: any): Promise<any> => {
        let requestBody = {
            requestType: request.type,
            attender: user.id,
            admin: request.admin,
            requestorName: request.nameOfRequestor,
            destination: '',
            status: REQUESTS_STATUS_ID.AUTHORIZED,
            remark1: request.remark1,
            requestor: request.requestor,
            driver: request.driver,
            vehicle: request.type == 0 ? request.vehicle_id: request.vehicle,
            attenderName: `${user.fname} ${user.lname}`,
            amount: request.amount,
            price: request.price
        }
        if ('destination' in request) {
            requestBody.destination = request.destination
        }
        
        console.log("🚀 ~ RequestService ~ authorizeRequest= ~ requestBody:", requestBody)
        try {
            const response = await this.client.put(`/request/${request.id}`, requestBody);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }

    processRequest = async (request: IRequest | IFuelRequest | IMaintenanceRequest, user: User | IUserData, approveRejectFlag: boolean): Promise<ApiResponse|any> => {
        let requestBody = {
            requestType: request.type,
            admin: request.admin,
            requestorName: request.nameOfRequestor,
            status: approveRejectFlag ? REQUESTS_STATUS_ID.APPROVED: (user.id == request.requestor ? REQUESTS_STATUS_ID.CANCELLED :REQUESTS_STATUS_ID.REJECTED),
            remark: request.remark,
            requestor: request.requestor,
            destination: '',
            closed_by: 0,
            attender: user.id,
            approved_by: request.approved_by,
            attenderName: request.nameOfApprover,
            vehicle: request.vehicle
        }
        console.log("🚀 ~ RequestService ~ processRequest= ~ requestBody:", requestBody)
        if ('destination' in request) {
            requestBody.destination = request.destination
        }
        if(approveRejectFlag){
            requestBody.approved_by = user.id
        }
        else{
            requestBody.attender = user.id;
            requestBody.attenderName = request.nameOfAttender;
        }
        try {
            const response = await this.client.put(`/request/${request.id}`, requestBody);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    getLastRequestOnVehicle = async (vehicle: string, requestType:number,requestId: number): Promise<ApiResponse> =>{
        try {
            const response = await this.client.get(`/last_request/${vehicle}?type=${requestType}&req=${requestId}`);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    completeFuelRequest = async (request:Partial<IFuelRequest>): Promise<ApiResponse>=>{
        const requestBody = {
            status: REQUESTS_STATUS_ID.CLOSED,
            closer: request.closed_by,
            comment: request.comment,
            price: request.a_price,
            amount: Number(request.a_amount),
            mileage: request.a_mileage,
            date_processed: request.date_processed,
            vehicle: request.vehicle,
            requestType: 1

        }
        console.log("🚀 ~ RequestService ~ authorizeRequest= ~ requestBody:", requestBody)
        try {
            const response = await this.client.put(`/request/${request.id}`, requestBody);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data,
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
    completeMaintenanceRequest = async (request:Partial<IMaintenanceRequest>): Promise<ApiResponse>=>{
        const requestBody = {
            status: REQUESTS_STATUS_ID.CLOSED,
            closer: request.closed_by,
            remark: request.comment,
            cost: request.a_cost,
            mileage: request.a_mileage,
            date_processed: request.date_processed,
            vehicle: request.vehicle,
            requestType: request.type

        }
        console.log("🚀 ~ RequestService ~ authorizeRequest= ~ requestBody:", requestBody)
        try {
            const response = await this.client.put(`/request/${request.id}`, requestBody);
            return {
                status: response.status,
                statusText: response.statusText,
                data: response.data,
            }
        }
        catch (e: any) {
            const error = e as unknown as AxiosError;
            return {
                status: error.isAxiosError ? error.status! : 400,
                statusText: error.isAxiosError ? error.code! : 'Bad Request',
                data: error.response
            }
        }
    }
}