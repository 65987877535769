
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from 'yup'
import { getQueryString, getStatusBadge, getStoredUserData, isAdmin } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiClient } from "../../utils/apiclient";
import { IDepartmentDetail, IUser, IUserDepartment, IUserPosition, User } from "../../utils/types";
import { toast } from 'react-toastify';
import { MdClose, MdEdit } from "react-icons/md";
import { CareAvatarImage } from "../../components/image-assets";
import RequestStatusBadge from "../../components/request_status";
import Image from '../../components/image'
import { getCityNames } from 'postcodes-tz'
import { Formik, FormikHelpers, FormikValues } from "formik";
import Spinner from "../../components/spinner";
import { FormControlLabel, Switch } from "@material-ui/core";
import { USER_POSITIONS } from "../../utils/constants";

const AccountEditUserForm = (props: any) => {
    const userData = getStoredUserData();
    const client = new ApiClient();
    const navigate = useNavigate()
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`

    const [departments, setDepartments] = useState<IUserDepartment[]>([])
    const [positions, setPositions] = useState<IUserPosition[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [checked,setChecked] = useState<boolean>(userData?.busy == 1)

    
    useEffect(() => {
        loadData()
    }, [])

    const sendUserUpdateForm = async (formData: IUser) => {
        setLoading(true)
        const request = await client.updateUser(formData, formData.id!);
        setLoading(false)
        if (request.status == 201 || request.status == 200) {
            toast.success(request.data, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            setTimeout(() => {
                navigate('/login',{state:{targetPath: target}})
            }, 5000)
        }
        else alert(request.data)
    }

    const formHandler = async (values: any) => {
        const user: IUser = {
            id: userData?.id,
            email: values.email,
            phone: values.phone,
            emp_id: values.emp_id,
            username: values.email,
            fname: values.fname,
            lname: values.lname,
            location: values.location,
            department: Number(values.department),
            position: Number(values.position),
            password: values.password,
            busy: checked ? 1:0
        }
        console.log("🚀 ~ formHandler ~ user:", user)
        const result = await sendUserUpdateForm(user)
        console.log("🚀 ~ formHandler ~ result:", result)
        
    }
    const formValidationSchema = Yup.object({
        email: Yup.string().email('Please enter a valid email address'),
        fname: Yup.string().min(2, "Name too short"),
        lname: Yup.string().min(2, 'Name too short'),
        department: Yup.number().required('Please select department'),
        position: Yup.number().required('Please select position'),
        password: Yup.string().min(8, 'Password is too short'),
        phone: Yup.string().required("Please enter a phone number").min(10,"Incorrect phone number")
    })
    const handleClose = () => {
        props.onClose()
    }

    const loadData = async () => {
        const [positions, departments] = await Promise.all([client.getPositions(), client.getDepartments()]);
        setDepartments(departments.data.departments)
        setPositions(positions.data.result)
    }
    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    const handleSwitchChange=(e:ChangeEvent<HTMLInputElement>)=>{
        setChecked(e.target.checked);
    }
    return (<>
        {loading ? <Spinner className="spinner-md mt-6" />:<div className="w-full flex justify-start items-start py-6 mx-auto border mt-6 h-full">
            <div className="py-4 w-full md:w-4/12">
                <Image src={CareAvatarImage} className="h-40 mx-auto" />
                {isAdmin(userData) ? <div className="w-full flex flex-col justify-center items-center space-x-5 mt-5 py-4">
                    <p className="mt-2 text-1xl mx-auto "><FormControlLabel control={<Switch defaultChecked={checked} onChange={handleSwitchChange} color="primary"/>} label={checked ? `Make Available`:'Make Assigned'} /></p>
                    <p className="mt-2 text-1xl mx-auto "><RequestStatusBadge status={getStatusBadge(checked ? 1:0, 'user')} /></p>
                </div>: <div className="w-full flex flex-col justify-center items-center space-x-5 mt-5 py-4">
                    <p className="mt-2 text-1xl mx-auto "><RequestStatusBadge status={getStatusBadge(userData?.busy!, 'user')} /></p>
                </div>}
            </div>
            <Formik validationSchema={formValidationSchema} initialValues={{ emp_id: '', phone: userData?.phone, email: userData?.email, password: "", lname: userData?.lname, fname: userData?.fname, position: userData?.position, department: userData?.department!, location: userData?.location }} onSubmit={formHandler}>
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <form
                        name="new-user-form"
                        id="new-user-form"
                        className="w-100"
                        onSubmit={handleSubmit}>
                        <div className="w-full flex justify-start items-start pb-8">
                            <div className="ms-5 md:w-6/12">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Username </label>
                                    <input onChange={handleChange} type="text" className="form-control" defaultValue={userData?.username} id="username" name="username" disabled />
                                </div>
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">First Name</label>
                                    <input onChange={handleChange} type="text" className="form-control" defaultValue={values.fname} id="fname" name="fname" />
                                    {touched && errors.fname && <span className="text-red-400">{errors.fname}</span>}
                                </div>
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Email Address</label>
                                    <input onChange={handleChange} type="email" className="form-control" defaultValue={values.email} id="email" name="email" disabled={!isAdmin(userData)}/>
                                    {touched && errors.email && <span className="text-red-400">{errors.email}</span>}
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Department</label>
                                    {isAdmin(userData) ? 
                                    <select className="form-control" defaultValue={values.department} onChange={handleChange} id="department" name="department"  disabled={!isAdmin(userData)}>
                                        {departments.map((d: IDepartmentDetail) => {
                                            return <option key={d.id} value={d.id} selected={values.department == d.id}>{`${d.code}-${d.region}`}</option>
                                        })}
                                    </select>: <input type="text" className="form-control" defaultValue={`${userData?.department_detail?.code}-${userData?.department_detail?.region}`} onChange={handleChange} id="department" name="department"  disabled={!isAdmin(userData)}/>}
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Location</label>
                                    {isAdmin(userData) ? 
                                    <select className="form-control" defaultValue={values.location} onChange={handleChange} id="locatioin" name="location" disabled={!isAdmin(userData)}>
                                        {getCityNames('asc').map((d: string) => {
                                            return <option key={d} value={d}>{d}</option>
                                        })}
                                    </select>:<input type="text" className="form-control" defaultValue={userData?.location} onChange={handleChange} id="location" name="location" disabled={!isAdmin(userData)}/>}
                                </div>

                            </div>
                            <div className="ms-5 md:w-6/12">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Employee ID</label>
                                    <input onChange={handleChange} type="text" className="form-control" defaultValue={values.emp_id} id="emp_id" name="emp_id" disabled={!isAdmin(userData)} />
                                </div>
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Last Name</label>
                                    <input onChange={handleChange} type="text" className="form-control" defaultValue={values.lname} id="lname" name="lname" />
                                    {touched && errors.lname && <span className="text-red-400">{errors.lname}</span>}
                                </div>

                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Phone Number</label>
                                    <input onChange={handleChange} type="phone" className="form-control" defaultValue={values.phone} id="phone" name="phone" />
                                    {touched && errors.phone && <span className="text-red-400">{errors.phone}</span>}
                                </div>


                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Position</label>
                                    {isAdmin(userData) ? 
                                    <select className="form-control" defaultValue={values.position} onChange={handleChange} id="position" name="position" disabled={!isAdmin(userData)}>
                                        {positions.map((d: IUserPosition) => {
                                            return <option key={d.id} value={d.id} selected={values.position == d.id}>{d.value}</option>
                                        })}
                                    </select>:<input onChange={handleChange} type="text" className="form-control" defaultValue={userData?.position_detail?.value} id="position" name="position"  disabled={!isAdmin(userData)}/>}
                                </div>
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Password</label>
                                    <input onChange={handleChange} type="password" className="form-control" id="password" name="password" />
                                    {touched && errors.password && <span className="text-red-400">{errors.password}</span>}
                                </div>
                            </div>
                            <div className="m-4 flex-col justify-between items-end h-full space-y-4">
                                <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={handleClose} />

                            </div>
                        </div>
                        <div className="flex items-center justify-end me-6">
                           {Object.values(errors).length == 0 ? 
                                <button
                                    className="text-white bg-accent me-6"
                                    type="submit"
                                    id="submitUser"
                                >Save</button>:null}

                        </div>
                    </form>)}
            </Formik>
        </div>}
    </>);

}
export default AccountEditUserForm;
