import { useNavigate } from "react-router-dom"
import NotificationView from "./notification_view"
import { getStoredUserData, getUserSession, isAuthenticated } from "../utils/utils";
import { ApiClient } from "../utils/apiclient";
import { Notification } from "../utils/types";
import { useEffect, useState } from "react";

const TopStrip = (props:any)=>{
    const navigate = useNavigate()
    const user = props.user;

    const userData = getStoredUserData();
    const session = getUserSession()
    const apiClient = new ApiClient(session?.accessToken)
    const [notifications,setNotifications] = useState<any>({totalCount:0,notifications: []})
    const showNotifications=()=>{

    }
    const getNotifications = async () => {

        let axios = await apiClient.getUnreadNotifications(user.id);
        if (axios.status == 200 || axios.status==201) {
            const notifications = axios.data;
            setNotifications(notifications)
        }
    }
    useEffect(()=>{
        if(isAuthenticated()){
            if(userData){
                getNotifications()
            }
        }
        else navigate('/login')
    },[])
    return (
        <div className=" space-x-2 w-4/12 flex justify-end mt-0 items-center text-primary ">
        <p className="text-end py-4 cursor-pointer" onClick={()=>{navigate('/settings',{state:{defaultTab:5}})}}>{props.user?.fname}</p>
        <NotificationView count={notifications.totalCount} onNotificationClick={showNotifications} />
    </div>
    )
}
export default TopStrip;