import { useNavigate } from "react-router-dom";
import NotificationView from "../../components/notification_view"
import Sidebar from "../../components/sidebar"
import { getStoredUserData, isAdmin, isAuthenticated } from "../../utils/utils";
import Spinner from "../../components/spinner";
import { FocusEvent, ChangeEvent, useEffect, useState } from "react";
import { ApiClient } from "../../utils/apiclient";
import { Fundcode, IFuelRequest, IProjectDetail, IRequest, ISupplier, IUser, User, Vehicle } from "../../utils/types";
import { Formik } from "formik";
import * as yup from 'yup'
import { RequestService } from "../../utils/services/request.service";
import { toast, ToastContainer } from "react-toastify";
import TopStrip from "../../components/top_strip";

const FuelRequestForm = (_props: any) => {
    const apiClient = new ApiClient()
    const requestService = new RequestService(apiClient)
    const userData = getStoredUserData();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [requestors, setRequestors] = useState<User[]>([])
    const [fRequestors, setFRequestors] = useState<User[]>([])
    const [projects, setProjects] = useState<IProjectDetail[]>([])
    const [fundcodes, setFundcodes] = useState<Fundcode[]>([])
    const [activities, setActivities] = useState<string[]>([])
    const [vehicles, setVehicles] = useState<Vehicle[]>([])
    const [fVehicles, setFVehicles] = useState<Vehicle[]>([])
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>()
    const [selectedDriver, setSelectedDriver] = useState<User | null | undefined>(userData);
    const [suppliers, setSuppliers] = useState<ISupplier[]>([])

    const showNotifications = () => {
        navigate(`/notifications/${_props.user?.id}`)
    }
    const isUserAdmin = isAdmin(userData)
    const filterUsers = (filter: string) => {
        console.log("🚀 ~ filterUsers ~ filter:", filter)

        if (filter && filter.length > 0) {
            filter = filter.toLowerCase();
            const result = requestors.filter((u: User | IUser) => {
                return u.fname.toLowerCase().includes(filter) || u.lname.toLowerCase().includes(filter)
            });
            setFRequestors(result)
        }
        else setFRequestors(requestors)
    }
    const loadVehicleAndDrivers = async () => {
        const [drivers, vehicles] = await Promise.all([apiClient.getAvailableDrivers(), apiClient.getAvailableVehicles()]);
        if (isUserAdmin) {
            setRequestors(drivers);
            setFRequestors(drivers)
            setVehicles(vehicles)
            setFVehicles(vehicles)
        }
        else {
            setRequestors(drivers.filter((d: User) => d.id == userData?.id));
            setFRequestors(drivers.filter((d: User) => d.id == userData?.id))
            setVehicles(vehicles.filter((v: Vehicle) => v.location == userData?.location))
            setFVehicles(vehicles.filter((v: Vehicle) => v.location == userData?.location))
        }
    }

    const loadProjects = async () => {
        let result = []
        let projects = await apiClient.getAllProjects();
        if (projects && projects.status == 200) {
            result = projects.data.projects
        }
        setProjects(result);
        if (result.length > 0) {
            loadFundcodes(result[0].code)
        }

    }
    const loadSuppliers = async (location: string | null) => {
        let data = {
            location: location ? location : null
        }
        const axios = await apiClient.getSuppliers(data)
        console.log("🚀 ~ loadSuppliers ~ axios:", axios)
        setSuppliers(axios.data.suppliers)
    }
    const handleFundcodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const code = e.currentTarget.value;
        const fundcode = fundcodes.find((f: Fundcode) => f.code == code);
        setActivities(fundcode?.activities!!)
    }
    const loadFundcodes = async (projectCode: string) => {
        let result = []
        const fundcodes = await apiClient.getProjectFundcodes(projectCode)
        if (fundcodes && fundcodes.status == 200) {
            result = fundcodes.data.fundcodes
        }
        setFundcodes(result);
        setActivities(result.length > 0 ? result[0].activities : [])

    }
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        filterUsers(e.currentTarget.value)
    }
    const loadData = async () => {
        Promise.all([loadProjects(), loadVehicleAndDrivers()])
    }
    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value) {
            loadFundcodes(e.currentTarget.value);
        }
        else setFundcodes([])
    }
    const handleDriverSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const driver = requestors.find((u: User) => u.id == Number(e.currentTarget.value))
        const filterVehicles = vehicles.filter((v: Vehicle) => v.location == driver?.location)
        setSelectedDriver(driver)
        setFVehicles(filterVehicles)
    }
    const handleVehicleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const vehicle = vehicles.find((v: Vehicle) => v.pnumber == e.currentTarget.value)
        setSelectedVehicle(vehicle)
        loadSuppliers(vehicle?.location!)
    }
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        const input = document.activeElement as unknown as HTMLInputElement;
        input.type = 'date';

    }
    //validation schema
    const formValidationSchema = yup.object({
        requestor: yup.number().required(),
        vehicle: yup.string().required('Please select a vehicle'),
        supplier: yup.string().required(),
        price: yup.number().required('Please enter price'),
        quantity: yup.number().required('Please enter quantity'),
        required_date: yup.date().required('Please provide required date'),
        project: yup.string().required(),
        fundcode: yup.string().required(),

    })
    const formHandler = async (values: any) => {
        const keys: string[] = Object.keys(values);
        const activities: string[] = keys.filter((key: string) => key.includes('cb')).map((key: string) => key.substring(2));
        let start_time = `${values.required_date} ${values.start_time ? values.start_time + ':00' : '00:00:00'}`
        const data: IFuelRequest = {
            ...values,
            activity: activities.join(','),
            requestType: 1,
            admin: selectedDriver?.department_detail?.admin,
            approver: projects.find((project: IProjectDetail) => project.code == values.project)?.coordinator,
            mileage: selectedVehicle?.current_mileage,
            start_time: Date.parse(start_time) / 1000,
            amount: Number(values.quantity),
            nameOfRequestor: `${userData?.fname} ${userData?.lname}`

        }
        console.log("🚀 ~ formHandler ~ data:", data)

        try {
            setIsLoading(true)
            const response = await requestService.createRequest(data)
            if ([200, 201].includes(response.status)) {
                toast.success(response.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setTimeout(() => {
                    navigate(-1)
                }, 5000);

            }
        }
        catch (e: any) {
            console.log("🚀 ~ formHandler ~ e:", e)
            toast.error("Sorry, something went wrong. Call support", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isAuthenticated()) loadData();
        else navigate('/login', { state: { targetPath: '/requests/fuel/new' } })
    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-screen">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            <div className="md:w-9/12 mx-auto space-y-4 mt-2">
                <ToastContainer />
                <Formik validationSchema={formValidationSchema} initialValues={{ supplier: '', vehicle: '', required_date: '', price: 0, requestor: userData?.id, mileage: selectedVehicle?.current_mileage, quantity: "", destination: "", destination_city: userData?.location, start_time: "", project: "", fundcode: fundcodes.length > 0 ? fundcodes[0].code : '' }} onSubmit={formHandler}>
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <form
                            name="vehicle-request-form"
                            id="vehicle-request-form"
                            className="center-self border-all-main"
                            onSubmit={handleSubmit}
                        >
                            <p className="text-2xl font-bold my-3 text-start">Fuel Request</p>

                            <div className="mt-5 w-100 flex items-center justify-start flex-wrap space-x-2">
                                {isUserAdmin ? <div className="text-start w-5/12">
                                    <p className="text-textDefault">Search Staff</p>
                                    <input
                                        readOnly={!isUserAdmin}
                                        disabled={!isUserAdmin}
                                        onChange={handleChange}
                                        className="form-control "
                                        type="text"
                                        name="search_staff"
                                        id="search_staff"
                                        // value={searchValue}
                                        placeholder='Enter staff name'
                                    />
                                </div> : null}
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Requestor's Name  <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="requestor"
                                        id="requestor"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleDriverSelect(e)
                                        }}
                                        defaultValue={userData?.id}
                                    >
                                        {fRequestors.map((u) => {
                                            return (
                                                <option key={u.id} value={u.id} >
                                                    {u.fname + " " + u.lname}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                            </div>
                            <div className="mt-5 w-100 flex items-center justify-start flex-wrap space-x-2">
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Select Vehicle  <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="vehicle"
                                        id="vehicle"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleVehicleChange(e)
                                        }}
                                        defaultValue={selectedVehicle?.pnumber}
                                    ><option>--</option>
                                        {fVehicles.map((v) => {
                                            return (
                                                <option key={v.id} value={v.pnumber} >
                                                    {v.pnumber}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.vehicle && touched ? errors.vehicle : null}</small>
                                </div>
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Vehicle Mileage</p>
                                    <input
                                        disabled
                                        onChange={handleChange}
                                        className="form-control "
                                        type="text"
                                        name="mileage"
                                        id="mileage"
                                        defaultValue={selectedVehicle?.current_mileage}
                                        placeholder='Enter vehicle mileage'
                                    />
                                </div>
                            </div>
                            <div className="mt-5 w-100 flex items-start justify-start space-x-2">
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Required Date <span className="text-red-500">*</span></p>
                                    <input
                                        className="form-control w-50"
                                        type="date"
                                        name="required_date"
                                        id="required_date"
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                    />
                                    <small className="text-red-500">{errors && errors.required_date && touched ? errors.required_date : null}</small>
                                </div>
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Time</p>
                                    <input
                                        onChange={handleChange}
                                        className="form-control"
                                        type="time"
                                        name="start_time"
                                        id="start_time"
                                    />

                                </div>
                            </div>

                            <div className="mt-5 w-100 flex items-start justify-start flex-wrap space-x-2">
                                <div className="text-start w-4/12">
                                    <p className="text-textDefault">Select Supplier  <span className="text-red-500">*</span></p>
                                    <select
                                        className="form-control "
                                        name="supplier"
                                        id="supplier"
                                        onChange={handleChange}
                                        defaultValue={values.supplier}
                                    ><option>--</option>
                                        {suppliers.map((v) => {
                                            return (
                                                <option key={v.id} value={v.id} >
                                                    {v.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.supplier && touched ? errors.supplier : null}</small>
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Unit Price (TSH)  <span className="text-red-500">*</span></p>
                                    <input
                                        onChange={handleChange}
                                        className="form-control "
                                        type="number"
                                        name="price"
                                        id="price"
                                        defaultValue={values.price}
                                        placeholder='Enter unit price in TSH'
                                    />
                                    <small className="text-red-500">{errors && errors.price && touched ? errors.price : null}</small>
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Quantity (Liters)  <span className="text-red-500">*</span></p>
                                    <input
                                        onChange={handleChange}
                                        className="form-control "
                                        type="number"
                                        name="quantity"
                                        id="quantity"
                                        defaultValue={values.quantity}
                                        placeholder='Enter quantity in Ltrs'
                                    />
                                    <small className="text-red-500">{errors && errors.quantity && touched ? errors.quantity : null}</small>
                                </div>
                            </div>


                            <div className="mt-5 w-100 flex items-start justify-start flex-wrap space-x-2">
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Project <span className="text-red-500">*</span></p>
                                    <select
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleProjectChange(e)
                                        }}
                                        className="form-control"
                                        name="project"
                                        id="project"
                                    >
                                        <option>--</option>
                                        {projects && projects.map((project: IProjectDetail) => {
                                            return <option key={project.id} value={project.code}>{project.code}</option>;
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.project && touched ? errors.project : null}</small>
                                </div>
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Fund Code <span className="text-red-500">*</span></p>
                                    <select
                                        id="fundcode"
                                        name="fundcode"
                                        className="form-control"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFundcodeChange(e)
                                        }
                                        }
                                    >
                                        <option>--</option>
                                        {fundcodes.map((fcode) => {
                                            return <option key={fcode.id}>{fcode.code}</option>;
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.fundcode && touched ? errors.fundcode : null}</small>
                                </div>
                            </div>
                            <div className="mt-5 text-start w-10/12">
                                <p className="text-textDefault">Activities</p>
                                <div className="w-10/12 flex justify-start items-center space-x-2">
                                    {activities?.map((a) => {
                                        return (
                                            <span key={"lb" + a} className="flex justify-start items-center p-2">
                                                <input
                                                    className="w-100 "
                                                    type="checkbox"
                                                    id={"cb" + a}
                                                    value={a}
                                                    onChange={handleChange}
                                                />
                                                <span className="ms-1">{a}</span>
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="mt-5 w-100 flex items-center justify-even flex-wrap space-x-3">
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Account</p>
                                    <input
                                        className="form-control "
                                        type="text"
                                        name="account"
                                        id="account"
                                        placeholder="Account" onChange={handleChange}
                                    />
                                </div>

                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Source</p>
                                    <input id="source" name="source" className="form-control" placeholder="Source" onChange={handleChange} />
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Category</p>
                                    <input id="category" className="form-control" name="category" placeholder="Category" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mt-5 flex justify-between items-center w-10/12">
                                {isLoading ? <Spinner className="spinner-sm mt-4" /> : <button type="submit" id="submit" name="submit" className=" bg-accent p-2 text-center hover:bg-accentDark">Submit</button>}
                                <button type="button" className="bg-transparent border border-accent text-accent p-2 text-center" onClick={() => { navigate('/requests') }}>Cancel</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </main>
    </>)
}
export default FuelRequestForm