import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoginPage from './modules/home/login'
import PageNotFound from "./components/404"
import Dashboard from "./modules/home/dashboard"
import Users from "./modules/users/users"
import CreateUserForm from "./modules/users/create_user_form"
import UserView from "./modules/users/user_view"
import EditUserForm from "./modules/users/edit_user_form"
import Requests from "./modules/requests/requests"
import FuelRequestView from "./modules/requests/fuel_request_view"
import VehicleRequestView from "./modules/requests/vehicle_request_view"
import MaintenanceRequestView from "./modules/requests/maintenance_request_view"
import ServiceRequestView from "./modules/requests/service_request_view"
import Vehicles from "./modules/vehicles/vehicles"
import VehicleView from "./modules/vehicles/vehicle_view"
import Trips from "./modules/trips/trips"
import VehicleRequestForm from "./modules/requests/vehicle_request_form"
import TripView from "./modules/trips/trip_view"
import FuelRequestForm from "./modules/requests/fuel_request_form"
import FuelOrder from "./modules/requests/fuel_order"
import ServiceRequestForm from "./modules/requests/service_request_form"
import Settings from "./modules/settings/settings"
import VehicleForm from "./modules/vehicles/vehicle_form"
import VehicleEditForm from "./modules/vehicles/vehicle_edit_form"
import ReportsHome from "./modules/reports"

const MainRouter =(props:any)=>{

    return (
        <BrowserRouter>
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users/new" element={<CreateUserForm />} />
            <Route path="/users/view/:userId" element={<UserView />} />
            <Route path="/users/edit/:userId" element={<EditUserForm />} />
            <Route path="/users" element={<Users />} />
            <Route path="/vehicles/view/:vehicleId" element={<VehicleView />} />
            <Route path="/vehicles/edit/:vehicleId" element={<VehicleEditForm />} />
            <Route path="/vehicles/new/" element={<VehicleForm />} />
            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/trips/view/:tripId" element={<TripView />} />
            <Route path="/trips" element={<Trips />} />
            <Route path="/requests/vehicle/view/:reqId" element={<VehicleRequestView />} />
            <Route path="/requests/vehicle/new" element={<VehicleRequestForm />} />
            <Route path="/requests/vehicle/edit/:reqId" element={<VehicleRequestForm />} />
            <Route path="/requests/service/view/:reqId" element={<ServiceRequestView />} />
            <Route path="/requests/service/new" element={<ServiceRequestForm />} />
            <Route path="/requests/fuel/view/:reqId" element={<FuelRequestView />} />
            <Route path="/requests/fuel/new" element={<FuelRequestForm />} />
            <Route path="/requests/fuel/order" element={<FuelOrder />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/reports" element={<ReportsHome />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/*" element={<PageNotFound />} />
                
        </Routes>
        </BrowserRouter>
    )
}
export default MainRouter;