import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { Fundcode, IProjectDetail, Trip, Vehicle } from "../../utils/types";
import { formatNumber, getStatusBadge, getStoredUserData, getUserSession, isAdmin, makeYears } from "../../utils/utils";
import Image from "../../components/image";
import { CareCarImage } from "../../components/image-assets";
import { MdClose, MdEdit } from "react-icons/md";
import { ApiClient } from "../../utils/apiclient";
import { ChangeEvent, useEffect, useState } from "react";
import TripsTable from "../trips/trips_table";
import Spinner from "../../components/spinner";
import RequestStatusBadge from "../../components/request_status";
import TopStrip from "../../components/top_strip";
import { getCityNames } from 'postcodes-tz'
import { Formik } from "formik";
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';

const VehicleForm = (props: any) => {
    const session = getUserSession()
    const apiClient = new ApiClient(session?.accessToken);
    const userData = getStoredUserData();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [projects, setProjects] = useState<IProjectDetail[]>([])
    const [fundcodes, setFundcodes] = useState<Fundcode[]>([]);
    const [fFundcodes, setFFundcodes] = useState<Fundcode[]>([])


    const formValidationSchema = Yup.object({
        pnumber: Yup.string().required('Registration number required'),
        brand: Yup.string().required('Provide vehicle manufacturer'),
        model: Yup.string().required('Please enter the vehicle model'),
        year: Yup.number().required('Year of manufacturing'),
        project: Yup.string().required('Please select a project'),
        fundcode: Yup.string().required('Please provide a fundcode'),
        location: Yup.string().required("Please select a location"),
        chassis_no: Yup.string().required("Please enter chassis number or VIN"),
        date: Yup.date().required()
    })
    const handleCancel = () => {
        navigate(-1)
    }
    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    const getProjects = async () => {
        const axios = await apiClient.getActiveProjects();
        if (axios.data) {
            setProjects(axios.data.projects)
        }
    }
    const getFundcodes = async () => {
        const axios = await apiClient.getActiveFundcodes();
        if (axios.data) {
            setFundcodes(axios.data.fundcodes)
            setFFundcodes(axios.data.fundcodes)
        }
    }

    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const p = e.currentTarget.value;
        setFFundcodes(fundcodes.filter((f: Fundcode) => f.project == p))
    }

    const formHandler = async (values: any) => {
        console.log("🚀 ~ formHandler ~ values:", values)
        setIsLoading(true);
        values.date = Date.parse(values.date)/1000;
        console.log("🚀 ~ formHandler ~ values:", values)
        const axios = await apiClient.createVehicle(values);
        console.log("🚀 ~ formHandler ~ axios:", axios)
        toast.info(axios.data, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setIsLoading(false)
        setTimeout(()=>{
            navigate('/vehicles')
        },2000)
    }
    // getVehicleHisotory()
    useEffect(() => {
        getProjects();
        getFundcodes()
    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={3} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            <ToastContainer />
            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="w-full">
                    <div className="w-full flex items-center justify-between p2-4">
                        <p className="text-2xl font-bold">Add New Vehicle</p>
                    </div>
                    {isLoading ? <Spinner className="spinner-md mt-6"/>:
                    <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">

                        <Formik validationSchema={formValidationSchema} initialValues={{ date:"",chassis_no: "", pnumber: "", brand: "", model: "", year: "", mileage: 0, current_mileage: 0, project: "", location: userData?.location, fundcode: "" }} onSubmit={formHandler}>
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <form
                                    name="new-user-form"
                                    id="new-user-form" className="mt-4 mx-auto"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="w-full py-6 mx-auto">
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Registration</label>
                                                <input type="text" className="form-control" name="pnumber" id="pnumber" onChange={handleChange} placeholder="T335DPA" />
                                                {errors && errors.pnumber ? <small className="text-sm text-red-500">{errors.pnumber}</small> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Chassis Number</label>
                                                <input type="text" className="form-control" id="chassis_no" name="chassis_no" placeholder="Enter chassis number" onChange={handleChange}/>
                                                {errors && errors.chassis_no ? <span className="text-sm text-red-500">{errors.chassis_no}</span> : null}
                                            </div>
                                        </div>

                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Make</label>
                                                <input type="text" className="form-control" name="brand" id="brand" onChange={handleChange} placeholder="Toyota" />
                                                {errors && errors.brand ? <span className="text-sm text-red-500">{errors.brand}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Model</label>
                                                <input type="text" className="form-control" id="model" name="model" placeholder="Landcruiser" onChange={handleChange}/>
                                                {errors && errors.model ? <span className="text-sm text-red-500">{errors.model}</span> : null}
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Year of Manufacture</label>
                                                <select id="year" name="year" className="form-control" onChange={handleChange}><option>--</option>
                                                    {makeYears(30).map((y:number) => {
                                                            return <option key={y} value={y}>{y}</option>
                                                        
                                                    })}
                                                </select>
                                                {errors && errors.year ? <span className="text-sm text-red-500">{errors.year}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Initial Cost (Tsh)</label>
                                                <input type="number" className="form-control" id="cost" name="cost" placeholder="Enter cost" onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Current Mileage (Km)</label>
                                                <input type="number" className="form-control" id="current_mileage" name="current_mileage" defaultValue={0} placeholder="Vehicle Mileage"  onChange={handleChange}/>
                                                {errors && errors.current_mileage ? <span className="text-sm text-red-500">{errors.current_mileage}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Initial Mileage (Km)</label>
                                                <input type="number" className="form-control" id="mileage" name="mileage" placeholder="Enter initial mileage" onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Donor</label>
                                                <input type="text" className="form-control" id="donor" name="donor" placeholder="Enter donor" onChange={handleChange} />
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Current Location</label>
                                                <select
                                                    className="form-control "
                                                    defaultValue={userData?.location.trim()}
                                                    name="location"
                                                    id="location"
                                                    onChange={handleChange}
                                                >
                                                    {getCityNames("asc").map((c: string) => {
                                                        return <option key={c}>{c}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label>Select Project</label>
                                                <select
                                                    required
                                                    id="project"
                                                    name="project"
                                                    className="form-control" onChange={(e) => {
                                                        handleChange(e);
                                                        handleProjectChange(e);
                                                    }}><option>---</option>
                                                    {projects.map((p: IProjectDetail) => {
                                                        return <option key={p.id} value={p.code}>{p.code}</option>
                                                    })}</select>

                                                {errors && errors.project ? <span className="text-sm text-red-500">{errors.project}</span> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Fund Code</label>
                                                <select
                                                    id="fundcode"
                                                    name="fundcode"
                                                    className="form-control" onChange={handleChange}><option>---</option>
                                                    {fFundcodes.map((p: Fundcode) => {
                                                        return <option key={p.id} value={p.code}>{p.code}</option>
                                                    })}</select>
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            <div className="text-start mb-4 w-6/12">
                                                <label className="text-gray-500">Date Acquired</label>
                                                <input type="date" className="form-control" name="date" id="date" onChange={handleChange}  />
                                                {errors && errors.date ? <small className="text-sm text-red-500">{errors.date}</small> : null}
                                            </div>
                                            <div className="text-start mb-4 w-6/12">
                                                {/* <label className="text-gray-500">Chassis Number</label>
                                                <input type="text" className="form-control" id="chassis_no" name="chassis_no" placeholder="Enter chassis number" onChange={handleChange}/>
                                                {errors && errors.chassis_no ? <span className="text-sm text-red-500">{errors.chassis_no}</span> : null} */}
                                            </div>
                                        </div>
                                        <div className="space-x-4 flex justify-between items-start">
                                            {isLoading ? <Spinner className="spinner-sm-white my-2" /> :
                                                <button
                                                    className="text-white bg-accent"
                                                    type="submit"
                                                    id="submitUser"
                                                >Save</button>}
                                            <button
                                                id="cancel-user"
                                                className="border border-accent text-accent"
                                                onClick={handleCancel}
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                </form>)}
                        </Formik>
                    </div>}

                </div>
            </div>
        </main>
    </>)
}
export default VehicleForm;