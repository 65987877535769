
import { Formik } from "formik";
import React, { FormEvent, useEffect, useState } from "react";
import * as Yup from 'yup'
import NotificationView from "../../components/notification_view";
import Sidebar from "../../components/sidebar";
import { getStoredUserData } from "../../utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiClient } from "../../utils/apiclient";
import { IUser, IUserDepartment, IUserPosition, User } from "../../utils/types";
import { ToastContainer, toast } from 'react-toastify';
import Spinner from "../../components/spinner";
import TopStrip from "../../components/top_strip";

const EditUserForm = (props: any) => {
    const userData = getStoredUserData();
    const client = new ApiClient();
    const navigate = useNavigate()
    const location = useLocation()
    const user: User = location.state.user;


    const [departments, setDepartments] = useState<IUserDepartment[]>([])
    const [positions, setPositions] = useState<IUserPosition[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const handleCancel = () => {
        navigate(`/users/view/${user.id}`, { state: { user: user } })
    }
    useEffect(() => {
        loadData()
    }, [])

    const sendUserUpdateForm = async (formData: IUser) => {
        setLoading(true)
        const request = await client.updateUser(formData, user.id);
        setLoading(false)
        if (request.status == 201) {
            toast.success(request.data, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            setTimeout(() => {
                navigate('/users')
            }, 2000)
        }
        else alert(request.data)
    }

    const formHandler = (values: any) => {
        const department: IUserDepartment | undefined = departments.find((d: IUserDepartment) => d.id == values.department)
        const location = department ? department.region : 'Dar es salaam';
        const user: IUser = {
            email: values.email,
            phone: values.phone,
            emp_id: values.employee_id,
            username: values.email,
            fname: values.fname,
            lname: values.lname,
            location: location,
            department: Number(values.department),
            position: Number(values.position),
            password: values.password,
            busy: 0
        }
        console.log("🚀 ~ formHandler ~ user:", user)
        sendUserUpdateForm(user)
    }
    const formValidationSchema = Yup.object({
        email: Yup.string().email('Please enter a valid email address'),
        fname: Yup.string().min(2, "Name too short"),
        lname: Yup.string().min(2, 'Name too short'),
        department: Yup.number().required('Please select department'),
        position: Yup.number().required('Please select position'),
        password: Yup.string().min(8, 'Password is too short'),
        phone: Yup.string().required("Please enter a phone number")
    })

    const loadData = async () => {
        const [positions, departments] = await Promise.all([client.getPositions(), client.getDepartments()]);
        setDepartments(departments.data.departments)
        setPositions(positions.data.result)
    }
    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <ToastContainer />
        <main className="w-full flex bg-white min-h-full">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={2} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>

            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="w-full">
                    <div className="w-full flex items-center justify-between p2-4">
                        <p className="text-2xl font-bold">Edit User</p><Spinner />
                    </div>
                    <div className="w-full md:w-8/12 flex justify-center py-4 mx-auto">
                        <Formik validationSchema={formValidationSchema} initialValues={{ phone: user.phone, email: user.email, password: "", lname: user.lname, fname: user.fname, position: user.position, department: user.department, location: user.location,busy:user.busy }} onSubmit={formHandler}>
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <form
                                    name="new-user-form"
                                    id="new-user-form" className="w-full mt-4 border py-8"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="w-full flex justify-between items-center space-x-2 md:w-9/12 mx-auto">
                                        <div className="w-full text-start">
                                            <label className="text-gray-600" htmlFor="employee_id">
                                                Employee ID
                                            </label>
                                            <input onChange={handleChange}
                                                className="form-control"
                                                type="text"
                                                name="employee_id"
                                                id="employee_id"
                                                placeholder="Enter employee Id"
                                                defaultValue={user.username}

                                            />
                                        </div>
                                        <div className="w-full text-start">
                                            <label className="text-gray-600">
                                                Position
                                            </label>
                                            <select
                                                onChange={handleChange}
                                                className="form-control "
                                                name="position"
                                                id="position"
                                                defaultValue={values.position}
                                            >
                                                {positions.map((p: any) => {
                                                    return (
                                                        <option key={p.id} value={p.id} selected={user.position == p.id}>
                                                            {p.value}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {touched && errors.position && <span className="text-red-400">{errors.position}</span>}
                                        </div>
                                    </div>

                                    <div className="w-full flex justify-between items-center space-x-2 md:w-9/12 mx-auto">
                                        <div className="w-full text-start">
                                            <label
                                                htmlFor="fname" className="text-gray-600">
                                                First Name
                                            </label>
                                            <input onChange={handleChange}
                                                className="form-control "
                                                type="text"
                                                name="fname"
                                                id="fname"
                                                placeholder="First Name"
                                                defaultValue={user.fname}
                                            />
                                            {touched && errors.fname && <span className="text-red-400">{errors.fname}</span>}
                                        </div>
                                        <div className="w-full text-start">
                                            <label
                                                htmlFor="lname"
                                                className="text-gray-600"
                                            >
                                                Last Name
                                            </label>
                                            <input onChange={handleChange}
                                                className="form-control "
                                                type="text"
                                                name="lname"
                                                id="lname"
                                                placeholder="Last Name"
                                                defaultValue={user.lname}
                                            />
                                            {touched && errors.lname && <span className="text-red-400">{errors.lname}</span>}
                                        </div>
                                    </div>

                                    <div className="w-full flex justify-between items-center space-x-2 md:w-9/12 mx-auto">
                                        <div className="w-full text-start">
                                            <label className="text-gray-600" htmlFor="email">
                                                E-mail
                                            </label>
                                            <input onChange={handleChange}
                                                className="form-control "
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Enter email address"
                                                defaultValue={user.email}
                                            />
                                            {errors.position && <span className="text-red-400">{errors.position}</span>}

                                        </div>
                                        <div className="w-full text-start">
                                            <label
                                                htmlFor="phone"
                                                className="text-gray-600"
                                            >
                                                Phone
                                            </label>
                                            <input onChange={handleChange}
                                                className="form-control "
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                placeholder="Enter a phone number"
                                                defaultValue={user.phone}
                                            />
                                            {errors.phone && <span className="text-red-400">{errors.phone}</span>}

                                        </div>

                                    </div>


                                    <div className="w-full flex justify-between items-center space-x-2 md:w-9/12 mx-auto">
                                        <div className="w-full text-start">
                                            <label
                                                htmlFor="department"
                                                className="text-gray-600"
                                            >
                                                Department
                                            </label>
                                            <select
                                                onChange={handleChange}
                                                className="form-control "
                                                name="department"
                                                id="department"
                                                defaultValue={user.department}
                                            >
                                                {departments.sort((a: any, b: any) => {
                                                    if (b.description > a.description) return -1;
                                                    else if (b.description < a.description) return 1;
                                                    else return 0;
                                                }).map((d: any) => {
                                                    return (
                                                        <option key={d.id} value={d.id} selected={user.department == d.id}>
                                                            {d.description + "(" + d.code + ")"}
                                                        </option>
                                                    );
                                                })}
                                            </select>{errors.department && <span className="text-red-400">{errors.department}</span>}

                                        </div>
                                        <div className="w-full text-start">
                                            <label
                                                htmlFor="department"
                                                className="text-gray-600"
                                            >
                                                Location
                                            </label>
                                            <select
                                                onChange={handleChange}
                                                className="form-control "
                                                name="location"
                                                id="location"
                                                defaultValue={user.location}
                                            >
                                                {departments.sort((a: any, b: any) => {
                                                    if (b.region > a.region) return -1;
                                                    else if (b.region < a.region) return 1;
                                                    else return 0;
                                                }).map((d: any) => {
                                                    return (
                                                        <option key={d.id} value={d.id} selected={user.location == d.region}>
                                                            {d.region}
                                                        </option>
                                                    );
                                                })}
                                            </select>{errors.location && <span className="text-red-400">{errors.location}</span>}

                                        </div>
                                    </div>
                                    <div className="w-full md:w-9/12 mx-auto">
                                    <div className="w-full text-start">
                                            <label
                                                htmlFor="department"
                                                className="text-gray-600"
                                            >
                                                Status
                                            </label>
                                            <select
                                                onChange={handleChange}
                                                className="form-control "
                                                name="busy"
                                                id="busy"
                                                defaultValue={user.busy}
                                            >
                                               <option value={0}>Available</option>
                                               <option value={1}>Assigned</option>
                                            </select>{errors.busy && <span className="text-red-400">{errors.busy}</span>}

                                        </div>
                                        <div className="w-full text-start">
                                            <label
                                                htmlFor="password"
                                                className="text-gray-600"
                                            >
                                                Password
                                            </label>
                                            <input onChange={handleChange}
                                                className="form-control "
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="Enter a password"
                                            />
                                            {errors.password && values.password && <span className="text-red-400">{errors.password}</span>}
                                        </div>
                                    </div>


                                    <div className="w-full md:w-9/12 mx-auto">
                                        <span className="flex items-center justify-between">
                                        {loading ? <Spinner className="spinner-sm-white my-2" /> :
                                            <button
                                                className={`py-1 px-2 text-white w-full md:w-3/12 bg-accent `}
                                                type="submit"
                                                id="submitUser"
                                            >Save</button>}
                                            <button
                                                id="cancel-user"
                                                className="border border-accent text-accent"
                                                onClick={handleCancel}
                                            >
                                                CANCEL
                                            </button>
                                        </span>
                                    </div>
                                </form>

                            )
                            }
                        </Formik >

                    </div >
                </div>
            </div>
        </main>
    </>);

}
export default EditUserForm;
