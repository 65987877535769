import { useEffect, useState } from "react"
import { formatMoney, formatNumber, getMonth, getQueryString, getStoredUserData, getUserSession, isAdmin, isAuthenticated } from "../../utils/utils"
import { useNavigate } from "react-router-dom"
import ReactApexChart from "react-apexcharts"
import PageControl from "../../components/page_control"
import Sidebar from "../../components/sidebar"
import Spinner from "../../components/spinner"
import SummaryCard from "../../components/summary_card"
import TopStrip from "../../components/top_strip"
import trips from "../trips/trips"
import TripsTable from "../trips/trips_table"
import { ApiClient } from "../../utils/apiclient"
import { ReportService } from "../../utils/services/report.service"
import { IPaginationOptions, Trip } from "../../utils/types"
import { REPORT_TABS } from "../../utils/constants"
import Tab from "../../components/tab"
import FuelReport from "./fuel_report"
import MaintenanceReport from "./maintenance_report"
import SMSUsageReport from "./sms_usage_report"

const ReportsHome = () => {
    const userData = getStoredUserData();
    const session = getUserSession();
    const apiClient = new ApiClient(session?.accessToken);
    const reportService = new ReportService(apiClient)
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const tabs = REPORT_TABS;
    const navigate = useNavigate()
    const [trips, setTrips] = useState<Trip[]>([])
    const [notifications, setNotifications] = useState<any>({ totalCount: 0, notifications: [] })
    const [loading, setLoading] = useState<boolean>(false)
    const [reports, setReports] = useState<any>({ cancelledRequests: [], requestCountByType: [], pendingRequests: [], approvedRequests: [], authorizedRequests: [], rejectedRequests: [], closedRequests: [] })
    const [pageInfo, setPageInfo] = useState<any>({ totalPages: 0, totalCount: 0, currentPage: 1, pageSize: 15, hasNext: false, hasPrevious: false });
    const [pageSize, setPageSize] = useState<number>(20)
    const [page, setPage] = useState<number>(1)
    const [activeTab, setActiveTab] = useState<number>(1)

    const getPreviousPage = async () => {
        setPage(page - 1)
        const incompleteTrips = await loadIncompleteTrips({ page: page - 1, take: pageSize })
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious })
        setTrips(incompleteTrips.trips)
    }
    const getNextPage = async () => {
        setPage(page + 1);
        const incompleteTrips = await loadIncompleteTrips({ page: page + 1, take: pageSize })
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious })
        setTrips(incompleteTrips.trips)
    }
    const options = {
        chart: {
            id: 'apexchart-example',
            title: {
                text: 'Number of Requests', style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#263238'
                },
            },
            toolbar: { show: false }

        },

        xaxis: {
            categories: reports?.years
        }
    }

    const series = [{
        name: 'Pending',
        data: reports?.pendingRequests.map((p: any) => p.count)
    }, {
        name: 'Approved',
        data: reports?.approvedRequests.map((p: any) => p.count)
    }, {
        name: 'Authorized',
        data: reports?.authorizedRequests.map((p: any) => p.count)
    }, {
        name: 'Rejected',
        data: reports?.rejectedRequests.map((p: any) => p.count)
    }, {
        name: 'Cancelled',
        data: reports?.cancelledRequests.map((p: any) => p.count)
    }, {
        name: 'Closed',
        data: reports?.closedRequests.map((p: any) => p.count)
    }]

    const options2 = {
        chart: {
            title: 'Just a title',
            toolbar: { show: false }
        },

        labels: reports?.requestCountByType.map((c: any) => c.type == 2 ? 'Maintenance Request' : getRequestTypeText(c.type)),
    }
    const series2 = reports && reports.requestCountByType ? reports?.requestCountByType.map((c: any) => c.count) : []
    //    const series2: ,
    const handlePageSizeChange = async (pageSize: number) => {
        if (pageSize && pageSize >= 10) {
            setPageSize(pageSize);
            setPage(1);

        }
        else {
            setPageSize(10);
            setPage(1);
        }
        const incompleteTrips = await loadIncompleteTrips({ page: 1, take: pageSize })
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious });
        setTrips(incompleteTrips.trips)
    }
    const loadIncompleteTrips = async (options: IPaginationOptions) => {
        const result = await apiClient.getIncompleteTrips(options);
        return result.data;
    }
    const getNotifications = async (userId: number) => {

        let axios = await apiClient.getUnreadNotifications(userId);
        console.log("🚀 ~ getNotifications ~ axios:", axios)
        if (axios.status == 200 || axios.status == 201) {
            const notifications = axios.data;
            setNotifications(notifications)
        }
    }

    const loadData = async () => {
        setLoading(true)
        const [incompleteTrips, monthlyReports] = await Promise.all([loadIncompleteTrips({ page: page, take: pageSize }), getMonthlyReports()]);
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious })

        setTrips(incompleteTrips.trips)
        setReports(monthlyReports)
        setLoading(false)
    }
    const getMonthlyReports = async () => {
        try {
            const reports = await reportService.getMonthlySummary()
            if (reports.status == 200) {
                return reports.data
            }
            return null;
        }
        catch (e) {
            console.log("🚀 ~ getMonthlyReports ~ e:", e)
            return null;
        }

    }
    const handleTabSelect = (id: number) => {
        setActiveTab(id)
    }
    useEffect(() => {
        if (isAuthenticated()) {
            setActiveTab(1)
        }
        else navigate('/login', { state: { targetPath: '/reports' } })
    }, [])

    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-screen">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={notifications.length} user={userData} active={5} />
            </div>
            {loading ? <Spinner className="spinner-md mt-6" /> :
                <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                    <div className="flex items-center justify-start md:space-x-2 mt-6">
                        <div className="w-full flex items-baseline justify-start border-b-2">
                            {tabs.map((s: any) => {
                                return <Tab key={s.id} title={s.title} onSelect={handleTabSelect} id={s.id} active={activeTab == s.id} />
                            })}
                        </div>
                    </div>
                    {activeTab == 1 ?
                        <div className="w-full items-start justify-between">
                            {/* <p className="text-start">Fuel Report for {getMonth((new Date()).getMonth())}</p> */}
                            <FuelReport />
                        </div> : null}
                    {activeTab == 2 ?
                        <div className="w-full items-start justify-between">
                            {/* <p className="text-start">Fuel Report for {getMonth((new Date()).getMonth())}</p> */}
                            <MaintenanceReport />
                        </div> : null}
                        {activeTab == 3 ?
                        <div className="w-full items-start justify-between">
                            {/* <p className="text-start">Fuel Report for {getMonth((new Date()).getMonth())}</p> */}
                            <SMSUsageReport />
                        </div> : null}
                </div>}
                
               


        </main>
    </>)
}
export default ReportsHome

function getRequestTypeText(type: any) {
    throw new Error("Function not implemented.")
}
