import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { useState, FormEvent } from "react";
import { IDepartmentDetail, IUser } from "../../utils/types";
import { getCityNames } from "postcodes-tz";

const ProjectForm = (props:any)=>{
    const [open,setOpen] = useState<boolean>(props.state)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    props.onSubmit(formJson)
    handleClose();
  }
   
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete this form to create a new project
          </DialogContentText>
          <label>Project Name</label>
          <input
            autoFocus
            required
            id="name"
            name="name"
            type="text"
            className="form-control"
            placeholder="Enter project name"
          />
           <label>Project Code</label>
          <input
            autoFocus
            required
            id="code"
            name="code"
            type="text"
            className="form-control"
            placeholder="Enter project code"
          />
           <label>Start Date</label>
          <input
            autoFocus
            required
            id="start_date"
            name="start_date"
            type="date"
            className="form-control"
          />
           <label>Finish Date</label>
          <input
            autoFocus
            required
            id="end_date"
            name="end_date"
            type="date"
            className="form-control" />
          <label>Donor</label>
          <input
            autoFocus
            required
            id="donor"
            name="donor"
            type="text"
            placeholder="Enter donnor"
            className="form-control"
          />
          <label>Select Department</label>
           <select
            required
            id="department"
            name="department"
            defaultValue={'Dar es salaam'}
            className="form-control"><option>--</option>
            {props.departments.map((c:IDepartmentDetail)=>{
                return <option key={c.id} value={c.id}>{`${c.code}- ${c.region}`}</option>
            })}
            </select>
            <label>Choose Project Coordinator</label>
           <select
            required
            id="coordinator"
            name="coordinator"
            className="form-control"><option>--</option>
            {props.coordinators.map((c:IUser)=>{
                return <option key={c.id} value={c.id}>{`${c.fname} ${c.lname}`}</option>
            })}
            </select>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default ProjectForm