

import { ChangeEvent, FormEvent, useState } from "react";
import { IFuelRequest } from "../../utils/types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { DateTime } from "luxon";

const DateSelectorDialog = (props: any) => {

    const [open, setOpen] = useState<boolean>(props.state)
    const [startDate,setStartDate] = useState<Date>(DateTime.fromMillis(Date.now()).minus({month:1}).toJSDate())
    const [endDate,setEndDate] = useState<Date>(DateTime.fromMillis(Date.now()).toJSDate())

    const handleClose = () => {
        setOpen(false);
        props.onClose()
    }
    const handleChange=(e:ChangeEvent<HTMLInputElement>)=>{
        const el = e.currentTarget;
        if(el.id == 'startDate'){
            setStartDate(new Date(el.value))
        }
        if(el.id == 'endDate'){
            setEndDate(new Date(el.value))
        }
    }
    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        const e = event as unknown as FormEvent<HTMLFormElement>
        const formData = new FormData(e.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        props.onSubmit(formJson)
        handleClose();
    }

    return (<div className="w-8/12 mx-auto">
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleFormSubmit
            }}
        >
            <DialogTitle>Date Selection</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select date ranges for the reports you want to generate. Start date should be earlier than the end date
                </DialogContentText>

                <label>
                    Start Date
                </label>
                <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    className="form-control"
                    onChange={handleChange}
                />

                <label>
                    End Date
                </label>
                <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    className="form-control"
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className="text-accent">Cancel</button>
                <button type="submit" className="bg-green-700">Done</button>
            </DialogActions>
        </Dialog>
    </div>);
}
export default DateSelectorDialog;