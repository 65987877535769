import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import NotificationView from "../../components/notification_view";
import { getStoredUserData, getUserSession, isAdmin, isAuthenticated, isDriver } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import RequestTable from "./requests_table";
import PageControl from "../../components/page_control";
import SearchBox from "../../components/search_box";
import { ApiClient } from "../../utils/apiclient";
import Spinner from "../../components/spinner";
import { RequestService } from "../../utils/services/request.service";
import TopStrip from "../../components/top_strip";
import { toast, ToastContainer } from 'react-toastify'
import RequestApprovalDialog from "./request_approval_dialog";
import { IRequest, IMaintenanceRequest, IFuelRequest } from "../../utils/types";
import { REQUESTS_STATUS_ID } from "../../utils/constants";

const Requests = (_props: any) => {
    const navigate = useNavigate();
    const userData = getStoredUserData();
    const userSession = getUserSession()
    const apiClient = new ApiClient(userSession?.accessToken);
    const requestService = new RequestService(apiClient)
    const [requests, setRequests] = useState<any[]>([])
    const [filteredRequests, setFilteredRequests] = useState(requests)
    const [pageInfo, setPageInfo] = useState<any>({ totalPages: 0, totalCount: 0, currentPage: 1, pageSize: 15, hasNext: false, hasPrevious: false });
    const [pageSize, setPageSize] = useState<number>(20)
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedRequest, setSelectedRequest] = useState<IRequest | IMaintenanceRequest | IFuelRequest>()
    const [type, setType] = useState<string>()
    const [open, setOpen] = useState<boolean>(false)

   
    const handleClickNew = (e: React.MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.id;
        let path = 'vehicle';
        switch (id) {
            case 'btnVehicle':
            default:
                path = 'vehicle';
                break;
            case 'btnFuel':
                path = 'fuel';
                break;
            case 'btnService':
                path = 'service';
                break;

        }
        navigate(`/requests/${path}/new`)
    }
    const getPreviousPage = () => {
        setPage(page - 1)
    }
    const getNextPage = () => {
        setPage(page + 1);
    }
    const getRequests = async (pageSize: number, page: number) => {
        setLoading(true);
        const response = await requestService.getUserRequests(userData?.id!!, { page: page, take: pageSize });
        setLoading(false)
        if (response.status == 200) {
            const data = response.data;
            setPageInfo({ totalPages: response.data.totalPages, totalCount: response.data.totalCount, currentPage: response.data.page, pageSize: response.data.pageSize, hasNext: response.data.hasNext, hasPrevious: response.data.hasPrevious })
            setRequests(data.requests);
            setFilteredRequests(data.requests);
        }
    }

    const handlePageSizeChange = (pageSize: number) => {
        if (pageSize && pageSize >= 10) {
            setPageSize(pageSize);
            setPage(1)
        }
        else {
            setPageSize(10);
            setPage(1);
        }
    }

    const handleFilter = (keyword: string) => {
        if (keyword.length > 1) {
            const result = requests.filter((req) =>
                req.nameOfRequestor.toLowerCase().includes(keyword) ||
                // req.nameOfAdmin.toLowerCase().includes(keyword) ||
                req.comment?.toLowerCase().includes(keyword) ||
                req.destination?.toLowerCase().includes(keyword) ||
                req.destination_city?.toLowerCase().includes(keyword) ||
                req.project.toLowerCase().includes(keyword) ||
                req.fundcode.toLowerCase().includes(keyword) ||
                req.statusText.toLowerCase().includes(keyword)
            );
            setFilteredRequests(result);
        }
        else setFilteredRequests(requests);
    }
    const handleSelect = (item: IRequest | IMaintenanceRequest | IFuelRequest, type: string) => {
        if (type == 'view') {
            let requestType: string = 'vehicle';
            switch (item.type) {
                case 1:
                    requestType = 'fuel';
                    break;
                case 2:
                case 3:
                case 4:
                    requestType = 'service';
                    break;
                case 0:
                default:
                    requestType = 'vehicle'

            }
            navigate(`/requests/${requestType}/view/${item.id}?t=${item.type}`, { state: { request: item } })
        }
        else {
            setSelectedRequest(item);
            setType(type);
            setOpen(true)
        }

    }
    const handleSubmit = async (data: any) => {
       if(type == 'approve') await approveRequest(data);
       else if(type == 'reject') await rejectRequest(data)
    }
    const approveRequest = async (formData: any) => {
        let data: any = {};
        if (userData) {
            switch (selectedRequest?.type) {
                case 0:
                    data = {
                        ...selectedRequest,
                        remark: formData.remark
                    };
                    break;
                case 1:
                    data = {
                        ...selectedRequest,
                        nameOfApprover: `${userData.fname} ${userData.lname}`,
                        status: REQUESTS_STATUS_ID.APPROVED,
                        attender: userData?.id,
                        id: selectedRequest.id,
                        type: selectedRequest.type,
                        vehicle: selectedRequest.vehicle,
                        remark: formData.remark
                    };
                    break;
                case 2:
                case 3:
                case 4:
                    data = {
                        ...selectedRequest,
                        nameOfApprover: `${userData.fname} ${userData.lname}`,
                        status: REQUESTS_STATUS_ID.APPROVED,
                        attender: userData?.id,
                        id: selectedRequest?.id,
                        type: selectedRequest?.type,
                        vehicle: selectedRequest?.vehicle,
                        remark: formData.remark
                    }
            }
            
            try {
                setLoading(true)
                const req = await requestService.processRequest(data, userData, true);
                toast.info(req.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                
                getRequests(pageSize,page)
            }
            catch (e: any) {
                toast.error("Something went wrong, please contact support", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            finally {
                setLoading(false)

            }
        }

    }
    const rejectRequest = async (formData:any) => {
        if (userData) {
            const data:any = {
                ... selectedRequest,
                remark: formData.remark,
                attended_by: userData.id,
                closed_by: userData.id,
                nameOfAttender: `${userData.fname} ${userData.lname}`
            }
            setLoading(true)
            try {
                const axios = await requestService.processRequest(data, userData, false)
                console.log("🚀 ~ sendApproval ~ axios:", axios.data)

            }
            catch (e: any) {
                console.log("🚀 ~ sendApproval ~ e:", e)
                alert("Something went wrong")
            }
            finally {
                getRequests(pageSize,page)
                setLoading(false)
            }
        }

    }
    useEffect(() => {
        if (isAuthenticated()) getRequests(pageSize, page);
        else navigate('/login', { state: { targetPath: '/requests' } })
    }, [pageSize, page])

    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-screen">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>

            <div className="md:w-9/12 mx-auto space-y-4 mt-2">
                {selectedRequest && open ? <RequestApprovalDialog data={selectedRequest} type={type} state={open} onClose={() => { setOpen(false) }} onSubmit={handleSubmit} /> : null}
                <div className="flex space-x-2 w-full my-2 pb-2 items-center justify-between border-b-2">
                    <div className="w-full flex items-center justify-start space-x-2">
                        {!isDriver(userData) ? <button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12" onClick={handleClickNew} id="btnVehicle">Vehicle</button> : null}
                        {isDriver(userData) || isAdmin(userData) ? <><button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12" onClick={handleClickNew} id="btnFuel">Fuel</button>
                            <button className="bg-primaryLight text-textDefault hover:bg-accentDark hover:text-white px-2 py-2 w-2/12" onClick={handleClickNew} id="btnService">Service</button></> : null}

                    </div>
                </div>
                <div className="w-full text-textDefault mt-2">
                    <div className="w-full flex items-center justify-between pt-2">
                        <p className="text-2xl font-bold">Requests</p>
                        <SearchBox onChange={handleFilter} /></div>
                    <div className="w-full border-top flex-row flex-start bg-main-color align-items-center ">
                        <PageControl pageSize={pageSize} onNext={getNextPage} onPrevious={getPreviousPage} currentPage={page} totalPages={pageInfo.totalPages} onPageSizeChange={(pageSize: number) => { handlePageSizeChange(pageSize) }} hasNext={pageInfo.hasNext} hasPrevious={pageInfo.hasPrevious} />

                    </div>
                    {loading ? <Spinner className="spinner-md mt-6" /> : <RequestTable data={filteredRequests} user={userData} onSelect={handleSelect} />}


                </div>
            </div>
        </main >
    </>);
}
export default Requests;