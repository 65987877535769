
import { FormEvent, useState } from "react";
import { IFuelRequest } from "../../utils/types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const RequestApprovalDialog = (props: any) => {
    
    const [open,setOpen] = useState<boolean>(props.state)
    const [request,setRequest] = useState<IFuelRequest>(props.data)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    props.onSubmit(formJson)
    handleClose();
  }
   
    return (<div className="w-8/12 mx-auto">
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>{props.type == 'approve' ? "Confirm Request Approval": "Confirm Request Rejection"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Fill this form to ${props.type} this request. You may need to review the details before proceeding `}
          </DialogContentText>
         
           <label>Remark</label>
          <input
            placeholder="Write any remarks here"
            id="remark"
            name="remark"
            type="text"
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          {props.type == 'approve' ?  <button type="submit" className="bg-green-700">Approve</button>: <button type="submit" className="bg-red-500">Reject</button>}
        </DialogActions>
      </Dialog>
    </div>);
}
export default RequestApprovalDialog;