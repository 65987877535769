import TableHeader from "../../components/table_header"
import { VEHICLE_FUEL_REPORT_HEADINGS } from "../../utils/constants"
import { formatNumber } from "../../utils/utils"

const VehicleFuelTable = (props: any) => {

    return (
        <div className="w-full text-textDefault mt-4">
            <table className="table-fixed w-full text-left p-2 mx-auto border">
                <TableHeader data={VEHICLE_FUEL_REPORT_HEADINGS} align="center"/>

                <tbody className="w-full text-sm">
                    {props.data && props.data.map((row: any) => {
                        return (
                            <tr key={row.vehicle} className="border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer text-center" >
                                <td className="border-bottom px-1 py-2">{row.vehicle}
                                </td>
                                <td className="border-bottom px-1 py-2">{formatNumber(row.fuel)}
                                </td>
                                <td className="border-bottom px-1 py-2">{formatNumber(row.distance)}
                                </td>
                                <td className="border-bottom px-1 py-2">{formatNumber(row.cost)}
                                </td>
                                <td className="border-bottom px-1 py-2">{(row.consumption)}
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default VehicleFuelTable