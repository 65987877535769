
import { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import { getStoredUserData, getUserSession, isAdmin, isAuthenticated, isDriver } from "../../utils/utils";
import NotificationView from "../../components/notification_view";
import { useNavigate } from "react-router-dom";
import { ApiClient } from "../../utils/apiclient";
import SearchBox from "../../components/search_box";
import TripsTable from "./trips_table";
import { ISession, Trip } from "../../utils/types";
import TopStrip from "../../components/top_strip";

const Trips = (_props: any) => {
  const navigate = useNavigate();
  const notifications = []
  const userData = getStoredUserData();

  const [trips, setTrips] = useState([])
  const [filteredTrips, setFilteredTrips] = useState(trips)
  const session: ISession | null = getUserSession();

  const showNotifications = () => {
    navigate(`/notifications/${userData?.id}`)
  }
  const handleClickNew = () => {
    navigate('/trips/new')
  }

  const getTrips = async () => {
    const apiClient = new ApiClient(session?.accessToken);
    var response;
    if (isDriver(userData)) {
      response = await apiClient.getDriverTrips(userData?.id!)
    }
    else if (isAdmin(userData)) {
      response = await apiClient.getTrips();
    }

    if (response && response.status == 200) {
      const data = response.data;
      setTrips(data.trips)
      setFilteredTrips(data.trips)
    }
  }


  const handleFilter = (keyword: string) => {
    if (keyword && keyword.length > 0) {
      keyword = keyword.toLowerCase()
      const t = trips.filter((trip: Trip) => {
        return trip.vehicle.toLowerCase().includes(keyword) || trip.nameOfDriver?.toLowerCase().includes(keyword)
          || trip.nameOfRequestor?.toLowerCase().includes(keyword) || trip.destination.toLowerCase().includes(keyword)
          || trip.destination_city.toLowerCase().includes(keyword) || trip.start_city.toLowerCase().includes(keyword)
          || trip.start_location.toLowerCase().includes(keyword) || trip.project?.toLowerCase().includes(keyword) || trip.fundcode.toLowerCase().includes(keyword)
      })
      setFilteredTrips(t);
    }
    else setFilteredTrips(trips)
  }
  useEffect(() => {
    if (isAuthenticated()) getTrips();
    else navigate('/login', { state: { targetPath: '/trips' } })
  }, [])
  return (<>
    <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
      <TopStrip user={userData} />
    </div>
    <main className="w-full flex bg-white min-h-full">

      <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
        <Sidebar notificationCount={notifications.length} user={userData} active={4} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
      </div>

      <div className="md:w-9/12 mx-auto space-y-4 mt-2">

        <div className="w-full text-textDefault mt-2">
          <div className="w-full flex items-center justify-between pt-2">
            <p className="text-2xl font-bold">Trips</p>
            <SearchBox onChange={handleFilter} /></div>

          <TripsTable data={filteredTrips} />
        </div>
      </div>
    </main></>
  );
}

export default Trips;