import { useNavigate } from "react-router-dom";
import NotificationView from "../../components/notification_view"
import Sidebar from "../../components/sidebar"
import { getStoredUserData, isAdmin, isAuthenticated } from "../../utils/utils";
import Spinner from "../../components/spinner";
import { FocusEvent, ChangeEvent, useEffect, useState } from "react";
import { ApiClient } from "../../utils/apiclient";
import { Fundcode, IProjectDetail, IRequest, IUser, User } from "../../utils/types";
import { USER_POSITIONS, USERS_HEADINGS } from "../../utils/constants";
import { getCityNames } from "postcodes-tz";
import { Formik } from "formik";
import * as yup from 'yup'
import { DateTime } from 'luxon';
import { RequestService } from "../../utils/services/request.service";
import { toast, ToastContainer } from "react-toastify";
import TopStrip from "../../components/top_strip";

const VehicleRequestForm = (_props: any) => {
    const apiClient = new ApiClient()
    const requestService = new RequestService(apiClient)
    const userData = getStoredUserData();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [requestors, setRequestors] = useState<User[]>([])
    const [fRequestors, setFRequestors] = useState<User[]>([])
    const [projects, setProjects] = useState<IProjectDetail[]>([])
    const [fundcodes, setFundcodes] = useState<Fundcode[]>([])
    const [activities, setActivities] = useState<string[]>([])
    const showNotifications = () => {
        navigate(`/notifications/${_props.user?.id}`)
    }
    const isUserAdmin = isAdmin(userData)
    const filterUsers = (filter: string) => {
        if (filter && filter.length > 0) {
            filter = filter.toLowerCase();
            const result = requestors.filter((u: User | IUser) => {
                return u.fname.toLowerCase().includes(filter) || u.lname.toLowerCase().includes(filter)
            });
            setFRequestors(result)
        }
        else setFRequestors(requestors)
    }
    const loadUsers = async () => {
        let users = [userData] as unknown as User[]
        if (isUserAdmin) {
            users = await apiClient.getActiveUsers()
        }

        setRequestors(users.filter((u: User) => u.position_detail?.value != USER_POSITIONS.DRIVER));
        setFRequestors(users.filter((u: User) => u.position_detail?.value != USER_POSITIONS.DRIVER))
    }
    const loadProjects = async () => {
        let result = []
        let projects = await apiClient.getAllProjects();
        if (projects && projects.status == 200) {
            result = projects.data.projects
        }
        setProjects(result);
        if (result.length > 0) {
            loadFundcodes(result[0].code)
        }

    }
    const handleFundcodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const code = e.currentTarget.value;
        const fundcode = fundcodes.find((f: Fundcode) => f.code == code);
        setActivities(fundcode?.activities!!)
    }
    const loadFundcodes = async (projectCode: string) => {
        let result = []
        const fundcodes = await apiClient.getProjectFundcodes(projectCode)
        if (fundcodes && fundcodes.status == 200) {
            result = fundcodes.data.fundcodes
        }
        setFundcodes(result);
        setActivities(result.length > 0 ? result[0].activities : [])

    }
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        filterUsers(e.currentTarget.value)
    }
    const loadData = async () => {
        Promise.all([loadUsers(), loadProjects()])
    }
    const handleProjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value) {
            loadFundcodes(e.currentTarget.value);
        }
        else setFundcodes([])
    }
    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        // if(e.currentTarget.id === 'today'){
        //     const dateInput: HTMLInputElement = document.getElementById('date') as unknown as HTMLInputElement;
        //     if(dateInput){
        //         dateInput.type = 'text';
        //         dateInput.value = (new Date()).toUTCString()
        //     }
        // }
        let label = 'date';
        // : HTMLInputElement = document.getElementById('date') as unknown as HTMLInputElement;
        switch (e.currentTarget.id) {
            case 'today':
                label = 'date_created';
                break;
            case 'rtoday':
                label = 'return_date';
                break;
            default:
                label = 'date_created';
        }
        const dateInput: HTMLInputElement = document.getElementById(label) as unknown as HTMLInputElement;
        if (dateInput) {
            dateInput.type = 'text';
            dateInput.value = DateTime.fromMillis(Date.now()).toLocaleString(DateTime.DATE_SHORT)
        }
    }
    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        const input = document.activeElement as unknown as HTMLInputElement;
        input.type = 'date';

    }
    //validation schema
    const formValidationSchema = yup.object({
        requestor: yup.number().required(),
        start_city: yup.string().required(),
        destination_city: yup.string().required(),
        location: yup.string().required(),
        destination: yup.string().required(),
        purpose: yup.string().required(),
        required_date: yup.date().required(),
        project: yup.string().required(),
        fundcode: yup.string().required(),

    })
    const formHandler = async (values: any) => {
        const keys: string[] = Object.keys(values);
        const activities: string[] = keys.filter((key: string) => key.includes('cb')).map((key: string) => key.substring(2));
        let start_time = `${values.required_date} ${values.start_time ? values.start_time + ':00' : '00:00:00'}`
        let end_time = `${values.return_date} ${values.end_time ? values.end_time + ':00' : '00:00:00'}`
        const data: IRequest = {
            ...values,
            activity: activities.join(','),
            requestType: 0,
            admin: userData?.department_detail?.admin,
            approver: projects.find((project: IProjectDetail) => project.code == values.project)?.coordinator,
            start_location: values.location,
            start_time: Date.parse(start_time) / 1000,
            end_time: Date.parse(end_time) / 1000
        }

        try {
            setIsLoading(true)
            const response = await requestService.createRequest(data)
            if ([200, 201].includes(response.status)) {
                toast.success(response.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setTimeout(() => {
                    navigate(-1)
                }, 5000);

            }
        }
        catch (e: any) {
            console.log("🚀 ~ formHandler ~ e:", e)
            toast.error("Sorry, something went wrong, please contact support", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isAuthenticated()) loadData();
        else navigate('/login', { state: { targetPath: '/requests/vehicle/new' } })
    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData}/>
        </div>
        <main className="w-full flex bg-white min-h-screen">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            <div className="md:w-9/12 mx-auto space-y-4 mt-2">
                <ToastContainer />
                <Formik validationSchema={formValidationSchema} initialValues={{search_staff:"", return_date: '', end_time: '', required_date: '', start_time: '', requestor: userData?.id, start_city: userData?.location, location: "", destination: "", destination_city: userData?.location, purpose: "", project: "", fundcode: fundcodes.length > 0 ? fundcodes[0].code : '' }} onSubmit={formHandler}>
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <form
                            name="vehicle-request-form"
                            id="vehicle-request-form"
                            className="center-self border-all-main"
                            onSubmit={handleSubmit}
                        >
                            <p className="text-2xl font-bold my-3 text-start">Request Vehicle</p>

                            <div className="mt-5 w-100 flex items-center justify-start flex-wrap space-x-2">
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Search Staff</p>
                                    <input
                                        readOnly={!isUserAdmin}
                                        disabled={!isUserAdmin}
                                        onChange={(e)=>{handleChange(e);handleSearch(e)}}
                                        className="form-control "
                                        type="text"
                                        name="search_staff"
                                        id="search_staff"
                                        // value={searchValue}
                                        placeholder='Enter staff name'
                                    />
                                </div>
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Requestor's Name</p>
                                    <select
                                        className="form-control "
                                        name="requestor"
                                        id="requestor"
                                        onChange={handleChange}
                                        defaultValue={userData?.id}
                                    >
                                        {fRequestors.map((u) => {
                                            return (
                                                <option key={u.id} value={u.id} >
                                                    {u.fname + " " + u.lname}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="mt-5 flex items-center justify-start space-x-2">
                                <fieldset className="w-5/12 border p-4">
                                    <legend className="text-start mx-3 px-3">From</legend>
                                    <div className="w-100 flex-row flex-start">
                                        <div className="text-start">
                                            <p className="text-textDefault">City/Region <span className="text-red-500">*</span>
                                            </p>
                                            <select
                                                disabled={!isUserAdmin}
                                                className="form-control "
                                                defaultValue={userData?.location.trim()}
                                                name="start_city"
                                                id="start_city"
                                                onChange={handleChange}
                                            >
                                                {getCityNames("asc").map((c: string) => {
                                                    return <option key={c}>{c}</option>;
                                                })}
                                            </select>
                                            <small className="text-red-500">{errors && errors.start_city && touched ? errors.start_city : null}</small>
                                        </div>
                                        <div className="text-start w-full">
                                            <p className="text-textDefault">Start Location<span className="text-red-500">*</span>
                                            </p>
                                            <input
                                                className="form-control "
                                                type="text"
                                                name="location"
                                                id="location"
                                                onChange={handleChange}
                                                placeholder="Enter your current location"
                                            />
                                            <small className="text-red-500">{errors && errors.location && touched ? errors.location : null}</small>
                                        </div>

                                    </div>
                                </fieldset>
                                <fieldset className="w-5/12 border p-4">
                                    <legend className="text-start mx-3 px-3">To</legend>
                                    <div className="text-start">
                                        <p className="text-textDefault">City/Region<span className="text-red-500">*</span></p>
                                        <select
                                            className="form-control "
                                            defaultValue={userData?.location.trim()}
                                            name="destination_city"
                                            id="destination_city"
                                            onChange={handleChange}
                                        >
                                            {getCityNames("asc").map((c: string) => {
                                                return <option key={c}>{c}</option>;
                                            })}
                                        </select>
                                        <small className="text-red-500">{errors && errors.destination_city && touched ? errors.destination_city : null}</small>
                                    </div>
                                    <div className="text-start">
                                        <p className="text-textDefault">
                                            Destination (Separate with commas (,))<span className="text-red-500">*</span>
                                        </p>
                                        <input
                                            className="form-control "
                                            type="text"
                                            name="destination"
                                            id="destination"
                                            placeholder="Enter list of destinations"
                                            onChange={handleChange}
                                        />
                                        <small className="text-red-500">{errors && errors.destination && touched ? errors.destination : null}</small>
                                    </div>

                                </fieldset>
                            </div>
                            <div className="mt-5 text-start w-10/12">
                                <p className="text-textDefault">Purpose<span className="text-red-500">*</span></p>
                                <input
                                    className="form-control "
                                    type="text"
                                    name="purpose"
                                    id="purpose"
                                    placeholder="Purpose of trip"
                                    onChange={handleChange}
                                />
                                <small className="text-red-500">{errors && errors.purpose && touched ? errors.purpose : null}</small>
                            </div>
                            <div className="mt-5 flex-row flex-start">
                                <div className="flex items-start justify-start text-start w-10/12">
                                    <div className="w-6/12">
                                        <p className="text-textDefault">Trip Date <span className="text-red-500">*</span></p>
                                        <input
                                            className="form-control w-50"
                                            type="date"
                                            name="required_date"
                                            id="required_date"
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                        />
                                        <small className="text-red-500">{errors && errors.required_date && touched ? errors.required_date : null}</small>
                                    </div>
                                    <div className="w-6/12 flex items-center justify-between">

                                        <div className="w-6/12 ">
                                            <p className="text-textDefault">Time</p>
                                            <input
                                                onChange={handleChange}
                                                className="form-control"
                                                type="time"
                                                name="start_time"
                                                id="start_time"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 flex-row flex-start">
                                <div className="flex items-center justify-start text-start w-10/12">
                                    <div className="w-6/12">
                                        <p className="text-textDefault">Expected Return Date</p>
                                        <input
                                            className="form-control w-50"
                                            type="date"
                                            name="return_date"
                                            id="return_date"
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                        />
                                    </div>
                                    <div className="w-6/12 flex items-center justify-between">

                                        <div className="w-6/12 ">
                                            <p className="text-textDefault">Time</p>
                                            <input
                                                onChange={handleChange}
                                                className="form-control"
                                                type="time"
                                                name="end_time"
                                                id="end_time"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 w-100 flex items-center justify-start flex-wrap space-x-2">
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Project <span className="text-red-500">*</span></p>
                                    <select
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleProjectChange(e)
                                        }}
                                        className="form-control"
                                        name="project"
                                        id="project"
                                    >
                                        <option>--</option>
                                        {projects && projects.map((project: IProjectDetail) => {
                                            return <option key={project.id} value={project.code}>{project.code}</option>;
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.project && touched ? errors.project : null}</small>
                                </div>
                                <div className="text-start w-5/12">
                                    <p className="text-textDefault">Fund Code <span className="text-red-500">*</span></p>
                                    <select
                                        id="fundcode"
                                        className="form-control"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFundcodeChange(e)
                                        }
                                        }
                                    >
                                        <option>--</option>
                                        {fundcodes.map((fcode) => {
                                            return <option key={fcode.id}>{fcode.code}</option>;
                                        })}
                                    </select>
                                    <small className="text-red-500">{errors && errors.fundcode && touched ? errors.fundcode : null}</small>
                                </div>
                            </div>
                            <div className="mt-5 text-start w-10/12">
                                <p className="text-textDefault">Activities</p>
                                <div className="w-10/12 flex justify-start items-center space-x-2">
                                    {activities?.map((a) => {
                                        return (
                                            <span key={"lb" + a} className="flex justify-start items-center p-2">
                                                <input
                                                    className="w-100"
                                                    type="checkbox"
                                                    id={"cb" + a}
                                                    value={a}
                                                    onChange={handleChange}
                                                />
                                                <span className="ms-1">{a}</span>
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="mt-5 w-100 flex items-center justify-even flex-wrap space-x-3">
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Account</p>
                                    <input
                                        className="form-control "
                                        type="text"
                                        name="account"
                                        id="account"
                                        placeholder="Account" onChange={handleChange}
                                    />
                                </div>

                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Source</p>
                                    <input id="source" name="source" className="form-control" placeholder="Source" onChange={handleChange} />
                                </div>
                                <div className="text-start w-3/12">
                                    <p className="text-textDefault">Category</p>
                                    <input id="category" className="form-control" name="category" placeholder="Category" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mt-5 flex justify-between items-center w-10/12">
                                {isLoading ? <Spinner /> : <button type="submit" id="submit" name="submit" className=" bg-accent text-center hover:bg-accentDark">Submit</button>}
                                <button type="button" className="bg-transparent border border-accent text-accent p-2 text-center" onClick={() => { navigate('/requests') }}>Cancel</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </main>
    </>)
}
export default VehicleRequestForm