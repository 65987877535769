
import { FormEvent, useState } from "react";
import { IFuelRequest } from "../../utils/types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const FuelRequestCompleteForm = (props: any) => {
    
    const [open,setOpen] = useState<boolean>(props.state)
    const [request,setRequest] = useState<IFuelRequest>(props.data)

    const handleClose = ()=>{
        setOpen(false);
        props.onClose()
    }
 const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const e = event as unknown as FormEvent<HTMLFormElement>
    const formData = new FormData(e.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    props.onSubmit(formJson)
    handleClose();
  }
   
    return (<>
       <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleFormSubmit
        }}
      >
        <DialogTitle>Complete Fuel Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill this form to complete a fuel request, providing the actual amount and price of fuel
          </DialogContentText>
          <label>Date Refueled</label>
          <input
            autoFocus
            required
            id="date"
            name="date"
            type="date"
            className="form-control"
          />
          <label>Actual Fuel Amount (Ltr)</label>
          <input
            autoFocus
            required
            id="amount"
            name="amount"
            type="number"
            defaultValue={request.amount}
            className="form-control"
          />
          <label>Actual Price Per Ltr (Tsh)</label>
           <input
            autoFocus
            required
            id="price"
            name="price"
            type="number"
            className="form-control"
            defaultValue={request.price}
          />
           <label>Actual Mileage (Km)</label>
           <input
            autoFocus
            required
            id="mileage"
            name="mileage"
            type="number"
            className="form-control"
            defaultValue={request.mileage}
          />
           <label>Comment</label>
          <input
          placeholder="Any comment"
            id="comment"
            name="comment"
            type="text"
            defaultValue={request.comment}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="text-accent">Cancel</button>
          <button type="submit" className="bg-green-700">Save</button>
        </DialogActions>
      </Dialog>
    </>);
}
export default FuelRequestCompleteForm;