import { useEffect, useState } from "react"
import { MdFileDownload } from "react-icons/md"
import { isAuthenticated, getUserSession, formatNumber, formatMoney } from "../../utils/utils"
import { ApiClient } from "../../utils/apiclient"
import { useNavigate } from "react-router-dom"
import { ReportService } from "../../utils/services/report.service"
import ReactApexChart from "react-apexcharts";
import { DateTime } from "luxon"
import DateSelectorDialog from "./date_picker_dialog"
import exportFromJson from 'export-from-json'
import SMSReportTable from "./sms_table"
import Spinner from "../../components/spinner"
import Tooltip from "@material-ui/core/tooltip"
import CalendarView from "@material-ui/icons/CalendarTodaySharp"

const SMSUsageReport = () => {
    const session = getUserSession();
    const apiClient = new ApiClient(session?.accessToken)
    const reportService = new ReportService(apiClient)
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState<number>(DateTime.fromMillis(Date.now()).minus({ month: 1 }).toMillis())
    const [endDate, setEndDate] = useState<number>(Date.now())
    const [reports, setReports] = useState<any>();
    const [series, setSeries] = useState<any[]>([])
    const [seriesCost, setSeriesCost] = useState<any[]>([])
    const [vehicles, setVehicles] = useState<string[]>([])
    const [options, setOptions] = useState<any>()
    const [optionsCost, setOptionsCost] = useState<any>()
    const [open, setOpen] = useState<boolean>(false)
    const [loading,setLoading] = useState<boolean>(false)
    // const [seriesConsumption, setSeriesConsumption] = useState<any[]>([])
    // const [seriesOptionsConsumption, setSeriesOptionsConsumption] = useState<any>()

    const getSMSReports = async (startDate: number, endDate: number) => {
        setLoading(true)
        const reports = await reportService.getSMSReport(startDate, endDate);

        if (reports.status == 200) {
            
            setReports(reports?.data)
            const options = {
                chart: {
                    id: 'distance-report',
                    toolbar: { show: true }

                },
                title: {
                    text: 'SMS Usage'
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                tooltip: {
                    y: {
                        formatter: function (val: any) {
                            return val
                        }
                    },
                    position: 'top'
                },
                xaxis: {
                    categories: reports?.data?.usage.map((r: any) =>r.label_month_year)
                }
            }
            const optionsCost = {
                chart: {
                    id: 'sms-cost-report',
                    toolbar: { show: true }

                },
                title: {
                    text: 'SMS Cost'
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                tooltip: {
                    y: {
                        formatter: function (val: any) {
                            return val
                        }
                    },
                    position: 'top'
                },
                xaxis: {
                    categories: reports?.data?.usage.map((r: any) =>r.label_month_year)
                }
            }
            const series = [
                {name: "SMS Count", data: reports?.data?.usage.map((r:any)=>r.count)},
            ]
            const seriesCost = [
                {name: "SMS Count", data: reports?.data?.usage.map((r:any)=>r.cost)},
            ]
            setOptions(options);
            setOptionsCost(optionsCost)
            setSeries(series)
            setSeriesCost(seriesCost)
        }

        setLoading(false)
    }
    const handleCalendarClick = () => {
        setOpen(true)
    }
    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login', { state: { targetPath: window.location.pathname } })
        }
        else {
            getSMSReports(startDate, endDate);
        }

    }, [startDate,endDate])
    const handleSubmit = (formData: any) => {
        setStartDate(Date.parse(formData.startDate))
        setEndDate(Date.parse(formData.endDate))
    }
    const downloadFile = () => {
        const data = reports?.usage
        const fileName = 'sms-usage';
        const exportType = 'csv';
        exportFromJson({ data, fileName, exportType })
    }
    return (<>
        <div className="w-full">
            {isAuthenticated() ?
                <div className="w-full flex flex-col justify-start items-start mx-auto border p-4">
                    {open ? <DateSelectorDialog state={open} onClose={() => { setOpen(false) }} onSubmit={handleSubmit} /> : null}
                    {loading ? <Spinner className="spinner-md mt-6"/> : <>
                    <div className="flex justify-between items-center space-x-4"><p className="text-md">{`Report for ${DateTime.fromMillis(startDate).toLocaleString(DateTime.DATETIME_MED)} - ${DateTime.fromMillis(endDate).toLocaleString(DateTime.DATETIME_MED)}`}</p>
                    <Tooltip title="Select date">
                        <CalendarView className="text-accent text-1xl cursor-pointer" onClick={handleCalendarClick} />
                    </Tooltip>
                    </div>
                    {reports ? <><div className="w-full flex justify-start space-x-4 items-start mx-auto text-gray-500 py-4 text-sm border-b border-b-gray-400">
                        <span>Total SMS Usage: <span className="font-bold">{formatNumber(reports.total)}</span></span>
                        <span>Tost Cost:  <span className="font-bold">{formatMoney(reports.totalCost)} Tsh</span></span>
                        <span>Av. Monthly Usage:  <span className="font-bold">{formatNumber(reports.averageCount)}</span></span>
                        <span>Av. Monthly Cost:  <span className="font-bold">{`${reports.averageCost} Tsh`}</span></span>
                    </div>
                    <div className="w-full flex justify-start items-start space-x-2 mt-4">
                        <div className="w-6/12 mx-auto">
                            {options && series ? <ReactApexChart type="line" options={options} series={series} /> : null}
                        </div>
                        <div className="w-6/12 mx-auto">
                            {options && seriesCost ? <ReactApexChart type="area" options={optionsCost} series={seriesCost} /> : null}
                        </div>
                    </div>
                    <div className="w-full mt-4">
                        <div className="w-full flex justify-between items-center">
                            <p>SMS Usage Report</p>
                            <MdFileDownload className="text-2xl text-accent cursor-pointer" onClick={downloadFile} />
                        </div>
                        <SMSReportTable data={reports?.usage} />
                    </div>
                    </> : <p className="text-center my-6">No Data</p>}
                </>}</div>

                : <span>You need to login</span>}
        </div >
    </>)
}
export default SMSUsageReport;