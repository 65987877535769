import { useNavigate } from "react-router-dom"
import TableHeader from "../../components/table_header"
import { DEPARTMENTS_HEADINGS, SUPPLIER_HEADINGS } from "../../utils/constants"
import { IDepartmentDetail, ISupplier, Trip } from "../../utils/types"
import { formatNumber, dateFormat } from "../../utils/utils"

const SupplierTable = (props:any)=>{
    const navigate = useNavigate()
    return (
    
        <table className="w-full text-left p-2 mx-auto border">
          <TableHeader data={SUPPLIER_HEADINGS} />
          <tbody className="w-full text-sm">
            {props.data && props.data.map((row:ISupplier) => {
              return (
                <tr key={row.id} className="text-sm border-b border-b-gray-200 py-8 hover:bg-gray-100 cursor-pointer px-2 items-start" onClick={()=>{navigate(`/settings/supplier/view/${row.id}`,{state:{supplier:row}})}}>
                  <td className="border-bottom px-1 py-2">{row.name}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.service}`}
                  </td>
                  <td className="border-bottom px-1 py-2">{`${row.region}`}</td>
                  <td className="border-bottom px-1 py-2">{row.tin}
                  </td>
                 
                  
                </tr>
              )
            })}
          </tbody>
        </table>
        )
}
export default SupplierTable