import { useLocation, useNavigate } from "react-router-dom";
import NotificationView from "../../components/notification_view";
import Sidebar from "../../components/sidebar";
import { Trip, User, Vehicle, VEHICLE_STATUS } from "../../utils/types";
import { formatNumber, getStatusBadge, getStoredUserData, getUserSession, isAdmin } from "../../utils/utils";
import Image from "../../components/image";
import { CareAvatarImage, CareCarImage, CareTripImage } from "../../components/image-assets";
import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { ApiClient } from "../../utils/apiclient";
import { useEffect, useState } from "react";
import TripsTable from "../trips/trips_table";
import Spinner from "../../components/spinner";
import { RequestService } from "../../utils/services/request.service";
import RequestStatusBadge from "../../components/request_status";
import TopStrip from "../../components/top_strip";

const VehicleView = (props: any) => {
    const session = getUserSession()
    const client = new ApiClient(session?.accessToken);
    const userData = getStoredUserData();
    const navigate = useNavigate()
    const location = useLocation();
    const state = location.state;
    const vehicle: Vehicle = state.vehicle ? state.vehicle : props.vehicle;
    const [vehicleHistory, setVehicleHistory] = useState<Trip[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    const getVehicleTripLogs = async () => {
        setIsLoading(true)
        const res = await client.getVehicleTripLog(vehicle.pnumber)
        setVehicleHistory(res.data.history.map(((x: any) => {
            return {
                ...x,
                statusText: x.status
            }
        })))
        setIsLoading(false)
    }
    // getVehicleHisotory()
    useEffect(() => {
        getVehicleTripLogs();
    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={3} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>

            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="w-full">
                    <div className="w-full flex items-center justify-between p2-4">
                        <p className="text-2xl font-bold">Vehicle Details</p>
                    </div>
                    <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                        <div className="p-4 w-full md:w-6/12">
                            <Image src={CareCarImage} height={200} />
                            <div className="text-start mb-4">
                                <p className="m-4 text-1xl mx-auto "><RequestStatusBadge status={getStatusBadge(vehicle.in_use, 'vehicle')} /></p>
                            </div>
                        </div>
                        <div className="ms-5 md:w-6/12">
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Registration: </label>
                                <p className="text-1xl">{`${vehicle.pnumber}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Make: </label>
                                <p className="text-1xl">{`${vehicle.brand}, ${vehicle.model}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Year of Manufacture: </label>
                                <p className="text-1xl">{`${vehicle.year}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Current Mileage (Km): </label>
                                <p className="text-1xl">{`${vehicle.current_mileage}`}</p>
                            </div>

                            <div className="text-start mb-4">
                                <label className="text-gray-500">Donor: </label>
                                <p className="text-1xl">{`${vehicle.donor}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Project: </label>
                                <p className="text-1xl">{`${vehicle.project ? vehicle.project : 'N/A'}`}</p>
                            </div>

                        </div>
                        <div className="ms-5 md:w-6/12">
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Chassis Number: </label>
                                <p className="text-1xl">{`${vehicle.chassis_no ? vehicle.chassis_no : 'N/A'}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Model: </label>
                                <p className="text-1xl">{`${vehicle.model}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Initial Cost (Tsh): </label>
                                <p className="text-1xl">{`${vehicle.a_cost}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Initial Mileage (Km): </label>
                                <p className="text-1xl">{`${vehicle.mileage}`}</p>
                            </div>
                            <div className="text-start mb-4">
                                <label className="text-gray-500">Current Location: </label>
                                <p className="text-1xl">{vehicle.location}</p>
                            </div>

                            <div className="text-start mb-4">
                                <label className="text-gray-500">Fund Code: </label>
                                <p className="text-1xl">{`${vehicle.fundcode ? vehicle.fundcode : 'N/A'}`}</p>
                            </div>
                        </div>

                        <div className="m-4 flex-col justify-between items-end h-full space-y-4">
                            <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/vehicles') }} />
                            {isAdmin(userData) ? <MdEdit className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate(`/vehicles/edit/${vehicle.id}`, { state: { vehicle: vehicle } }) }} /> : null}
                        </div>
                    </div>
                    <div className="w-full mx-auto mt-5 py-4">
                        {vehicleHistory ?
                            <><div className="text-textDefault text-1xl py-2 flex justify-between items-center">
                                <span className="font-bold text-center ">Trips Taken: {vehicleHistory.length}</span>
                                <span className="font-bold text-center">Distance Traveled: {formatNumber(vehicleHistory.reduce((total: number, initialValue: Trip) => total + (initialValue.end_mileage!! - initialValue.start_mileage), 0))} Km</span>
                            </div>
                                {isLoading ? <Spinner className="spinner-md mt-6" /> : <TripsTable data={vehicleHistory} />}</>
                            : null}
                    </div>
                </div>
            </div>
        </main>
    </>)
}
export default VehicleView;